import ReactDOM from 'react-dom/client';

import { Document, Footer, Header, Packer, PageNumber, NumberFormat, PageOrientation, Paragraph, TextRun } from "docx";
import { Table, TableCell, TableRow, WidthType, TableLayoutType, AlignmentType } from "docx";
import { BorderStyle, VerticalAlign } from "docx";
import { ImageRun } from "docx";
import * as fs from "file-saver";
import { ubb, default_toast_showtime } from "./pages/Consts.js"
import { Donut } from './pages/Donut/Donut';
import HiddenCanvases from './pages/Canvas/HiddenCanvases';

const convertSVGToPNG = (svgElement) => {
    return new Promise((resolve, reject) => {
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const img = new Image();
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            URL.revokeObjectURL(url);

            const pngData = canvas.toDataURL('image/png');
            resolve(pngData);
        };

        img.onerror = (error) => {
            URL.revokeObjectURL(url);
            reject(error);
        };

        img.src = url;
    });
};

// const convertBlobToBase64 = (blob) => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => resolve(reader.result);
//         reader.onerror = reject;
//         reader.readAsDataURL(blob);
//     });
// };

function rgbToHex(rgb) {
    // Extract the numbers using a regular expression
    const result = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/.exec(rgb);

    // Check if the input is valid
    if (!result) {
        throw new Error("Invalid RGB input");
    }

    // Convert the extracted RGB values to hexadecimal
    const r = parseInt(result[1], 10);
    const g = parseInt(result[2], 10);
    const b = parseInt(result[3], 10);

    // Ensure the values are in the range 0-255
    if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
        throw new Error("RGB values must be in the range 0-255");
    }

    // Convert each color component to a 2-digit hexadecimal value
    const toHex = n => n.toString(16).padStart(2, '0').toUpperCase();

    // Return the combined hex color code
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

// XXXXXXXXXXXXXXXXXXXXXXXXXX
// SAVING THE DOCX FILE START
// XXXXXXXXXXXXXXXXXXXXXXXXXX
// handleSave = () => {
//     // console.log(this.state);
//     const textFile = new Blob([JSON.stringify(this.state)], { type: 'text/plain' });
//     // console.log(textFile);
//     if (window.showSaveFilePicker) {
//         this.genFileNamePickerDialog(textFile);
//     }
//     else {
//         this.handleDirectSave(textFile);
//     }
// }

//   async genFileNamePickerDialog(workLoad) {
//     const opts = {
//         suggestedName: this.genFilename() + "_bearbeitungsstand",
//         types: [{
//             description: 'JSON Datei',
//             accept: { 'application/json': ['.json'] },
//         }],
//     };

//     try {
//         const handle = await window.showSaveFilePicker(opts);
//         const writable = await handle.createWritable();
//         await writable.write(workLoad);
//         await writable.close();
//         this.injectToast("Bearbeitungsstand gespeichert",
//             "Der aktuelle Bearbeitungsstand wurde unter dem Dateinamen '" + handle.name +
//             "' auf Ihrem lokalen Dateisystem gespeichert.",
//             default_toast_showtime,
//             new Date().toLocaleString(),
//             "info",
//             true);

//     }
//     catch (err) {
//         this.injectToast("Speichern abgebrochen",
//             "Der aktuelle Bearbeitungsstand wurde nicht gespeichert.",
//             default_toast_showtime,
//             new Date().toLocaleString(),
//             "info",
//             true);
//     }
// }

// handleDirectSave = (textFile) => {
//     let dlFileName = this.genFilename();
//     const element = document.createElement("a");
//     element.href = URL.createObjectURL(textFile);
//     element.download = dlFileName;
//     document.body.appendChild(element);
//     element.click();
//     document.body.removeChild(element);
// }

// genFilename() {
//     var invalid_chars = /[^a-zA-Z0-9_-]/g;
//     // const fn = new Date().toISOString().slice(0, 10) + "-Analyse" + "-" +
//     //   this.props.parentState.klassenarbeiten[0].schuljahr.replace(invalid_chars, "") + "-" +
//     //   this.props.parentState.klassenarbeiten[0].fach.replace(invalid_chars, "") + ".json";
//     return "ubb";
// }
// XXXXXXXXXXXXXXXXXXXXXXXXXX
// SAVING THE DOCX FILE START
// XXXXXXXXXXXXXXXXXXXXXXXXXX

// const saveAction = (blob, saveName, type) => {
//     if (type === 1) { // 1 => direct save
//         fs.saveAs(blob, saveName);
//     }
//     else if (type === 2) { // 2 => file picker action
//         console.log("file picker action");
//     }
// }

export const handleExportRequestAll = async ({ parentState, fn, type, fpHandle }) => {
    let ubbChartDivAvailable = true;
    const p_spacing_before = 200;
    const p_spacing_after = 200;
    let images = [];
    const formattedDate = (parentState.md[8].value === "") ? new Date().toLocaleDateString() :
        new Date(parentState.md[8].value).toLocaleDateString('de-DE');

    const paragraphs = parentState.protocol.data.split('\n').map(paragraphText => {
        return new Paragraph({
            children: [
                new TextRun(paragraphText)
            ]
        });
    });

    // await renderDonutIfNeeded();
    // const handleImagesGenerated = async (generatedImages) => {
    //     console.log("renderPrintContentFROM HIDDEN CANSIS()");
    //     images = generatedImages;

    //     await new Promise(resolve => setTimeout(resolve, 1000)); // VERY ugly hack!
    //     await renderExportContent();
    // };

    const ubbChartDiv = document.getElementById('ubbchart');
    if (ubbChartDiv === null) {
        ubbChartDivAvailable = false;

        const container = document.createElement('div');
        container.style.position = 'absolute';
        container.style.top = '-9999px';
        container.style.left = '-9999px';
        container.id = 'ubbchart';
        document.body.appendChild(container);

        const root = ReactDOM.createRoot(container);
        root.render(
            <Donut
                parentState={parentState}
                ubb={ubb}
                backCallerHandleChangeLevel={(e) => console.log("noOpperation")} // make noop method
            />
        );
        await new Promise(resolve => setTimeout(resolve, 200)); // VERY ugly hack!
    }

    const svgElement = document.getElementById('ubbchart').querySelector('svg');
    const pngData = await convertSVGToPNG(svgElement);

    const myStdTableCellMargins = {
        top: 100,
        bottom: 100,
        left: 40,
        right: 20,
    };

    const myStdHeaderTableCellMargins = {
        top: 100,
        bottom: 100,
        left: 40,
        right: 20,
    };

    const ubbRowsStufenHeader = [
        new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    size: 24,
                                    bold: true,
                                    text: "Erläuterungen zu den Stufen",
                                }),
                            ],
                        }),
                    ],
                    columnSpan: 12,
                    verticalAlign: VerticalAlign.CENTER,
                    margins: myStdTableCellMargins,
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    bold: true,
                                    text: "Stufe",
                                }),
                            ],
                        }),
                    ],
                    columnSpan: 4,
                    verticalAlign: VerticalAlign.CENTER,
                    margins: myStdTableCellMargins,
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.START,
                            children: [
                                new TextRun({
                                    bold: true,
                                    text: "Erläuterung",
                                }),
                            ],
                        }),
                    ],
                    columnSpan: 8,
                    verticalAlign: VerticalAlign.CENTER,
                    margins: myStdTableCellMargins,
                }),
            ],
        }),

    ];

    const ubbRowsStufen = ubb.stufen.map((item, index) => {
        return new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: item.id.toString(),
                                }),
                            ],
                        }),
                    ],
                    columnSpan: 4,
                    verticalAlign: VerticalAlign.CENTER,
                    margins: myStdTableCellMargins,
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: item.text,
                                }),
                            ],
                        }),
                    ],
                    columnSpan: 8,
                    verticalAlign: VerticalAlign.CENTER,
                    margins: myStdTableCellMargins,
                }),
            ],
        });
    });

    const createParagraphsWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line) => new Paragraph({
            children: [
                new TextRun(line)
            ]
        }));
    };

    const ubbRowsD1 = parentState.b
        .filter((item, index) => index <= 2)
        .map((item, index) => {
            return [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: `${ubb.beobachtungsfelder[index].name}`,
                                            color: rgbToHex(ubb.beobachtungsfelder[index].color),
                                            bold: true,
                                            // size: 24,
                                        }),
                                    ],
                                }),
                            ],
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,

                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun(ubb.beobachtungsfelder[index].sub),
                                    ],
                                }),
                            ],
                            columnSpan: 7,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,

                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun(parentState.b[index].level.toString()),
                                    ],
                                }),
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                ...createParagraphsWithLineBreaks(
                                    parentState.b[index].text.length > 0 ? parentState.b[index].text.toString() : "Keine Eintragungen."
                                ),
                            ],
                            columnSpan: 12,
                            margins: myStdTableCellMargins,
                        }),
                    ],
                }),
            ]
        }).flat();

    const ubbRowsD2 = parentState.b
        .filter((item, index) => index >= 3 && index <= 5)
        .map((item, index) => {
            return [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: `${ubb.beobachtungsfelder[index + 3].name}`,
                                            color: rgbToHex(ubb.beobachtungsfelder[index + 3].color),
                                            bold: true,
                                        }),
                                    ],
                                }),
                            ],
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,

                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun(ubb.beobachtungsfelder[index + 3].sub),
                                    ],
                                }),
                            ],
                            columnSpan: 7,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,

                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun(parentState.b[index + 3].level.toString()),
                                    ],
                                }),
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                ...createParagraphsWithLineBreaks(
                                    parentState.b[index + 3].text.length > 0 ? parentState.b[index + 3].text.toString() : "Keine Eintragungen."
                                ),
                            ],
                            columnSpan: 12,
                            margins: myStdTableCellMargins,
                        }),
                    ],
                }),
            ]
        }).flat();

    const ubbRowsD3 = parentState.b
        .filter((item, index) => index >= 6)
        .map((item, index) => {
            return [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: `${ubb.beobachtungsfelder[index + 6].name}`,
                                            color: rgbToHex(ubb.beobachtungsfelder[index + 6].color),
                                            bold: true,
                                        }),
                                    ],
                                }),
                            ],
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,

                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun(ubb.beobachtungsfelder[index + 6].sub),
                                    ],
                                }),
                            ],
                            columnSpan: 7,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,

                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun(parentState.b[index + 6].level.toString()),
                                    ],
                                }),
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            margins: myStdTableCellMargins,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                ...createParagraphsWithLineBreaks(
                                    parentState.b[index + 6].text.length > 0 ? parentState.b[index + 6].text.toString() : "Keine Eintragungen."
                                ),
                            ],
                            columnSpan: 12,
                            margins: myStdTableCellMargins,
                        }),
                    ],
                }),
            ]
        }).flat();

    const ubbTableStandardHeader =
        new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({ bold: true, text: "Beobachtungsfelder" }),
                            ],
                        }),
                    ],
                    columnSpan: 4,
                    margins: myStdTableCellMargins,
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({ bold: true, text: "Beschreibung" }),
                            ],
                        }),
                    ],
                    columnSpan: 7,
                    margins: myStdTableCellMargins,
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({ bold: true, text: "Stufe" }),
                            ],
                        }),
                    ],
                    columnSpan: 1,
                    margins: myStdTableCellMargins,
                }),
            ],
        });

    const ubbTableBreakerRow =
        new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph("")],
                    columnSpan: 12,
                    margins: {
                        // top: p_spacing_before,
                        bottom: p_spacing_after,
                    },
                    borders: {
                        top: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                        bottom: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                        left: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                        right: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                    },
                }),
            ],
        });

    const ubbTable = new Table({
        columnWidths: [750, 750, 750, 750, 750, 750, 750, 750, 750, 750, 750, 750],
        width: {
            size: 9000, // Total width of the table in DXA
            type: WidthType.DXA,
        },
        layout: TableLayoutType.FIXED,
        rows: [
            // #1 dimension
            new TableRow({
                children: [
                    new TableCell({
                        shading: {
                            fill: "f4f4f4",
                            color: "auto",
                            val: "clear",
                        },
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        bold: true,
                                        size: 30,
                                        text: "Dimension " + ubb.dimensionen[0].name,
                                    }),
                                ],
                            }),
                        ],
                        columnSpan: 12,
                        margins: myStdTableCellMargins,
                    }),
                ],
            }),
            ubbTableStandardHeader,
            ...ubbRowsD1,
            ubbTableBreakerRow,

            // #2 dimension
            new TableRow({
                children: [
                    new TableCell({
                        shading: {
                            fill: "f4f4f4",
                            color: "auto",
                            val: "clear",
                        },
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        bold: true,
                                        size: 30,
                                        text: "Dimension " + ubb.dimensionen[1].name,
                                    }),
                                ],
                            }),
                        ],
                        columnSpan: 12,
                        margins: myStdTableCellMargins,
                    }),
                ],
            }),
            ubbTableStandardHeader,
            ...ubbRowsD2,
            ubbTableBreakerRow,

            // #3 dimension
            new TableRow({
                children: [
                    new TableCell({
                        shading: {
                            fill: "f4f4f4",
                            color: "auto",
                            val: "clear",
                        },
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        bold: true,
                                        size: 30,
                                        text: "Dimension " + ubb.dimensionen[2].name,
                                    }),
                                ],
                            }),
                        ],
                        columnSpan: 12,
                        margins: myStdTableCellMargins,
                    }),
                ],
            }),
            ubbTableStandardHeader,
            ...ubbRowsD3,

            ubbTableBreakerRow,

            ...ubbRowsStufenHeader,
            ...ubbRowsStufen,
        ]
    });

    const headerTable = new Table({
        columnWidths: [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000],
        width: {
            size: 9000,
            type: WidthType.DXA,
        },
        layout: TableLayoutType.FIXED,
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        // width: {
                        //     size: 3505,
                        //     type: WidthType.DXA,
                        // },

                        // borders: {
                        //     top: {
                        //         style: BorderStyle.DASH_DOT_STROKED,
                        //         size: 3,
                        //         color: "FF0000",
                        //     },
                        //     bottom: {
                        //         style: BorderStyle.DOUBLE,
                        //         size: 3,
                        //         color: "0000FF",
                        //     },
                        //     left: {
                        //         style: BorderStyle.DASH_DOT_STROKED,
                        //         size: 3,
                        //         color: "00FF00",
                        //     },
                        //     right: {
                        //         style: BorderStyle.DASH_DOT_STROKED,
                        //         size: 3,
                        //         color: "#ff8000",
                        //     },
                        // },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun("Unterrichtende Lehrkraft: "),
                                    new TextRun({ bold: true, text: parentState.md[0].value }),
                                ],
                            }),
                        ],
                        columnSpan: 6,
                        // margins: myStdHeaderTableCellMargins,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: ["Datum: "]
                                    }),
                                    new TextRun({
                                        text: formattedDate,
                                        // color: "FF0000",
                                        bold: true,
                                        // size: 24,
                                        // font: {
                                        //     name: "Garamond",
                                        // },
                                        // highlight: "yellow",
                                    }),
                                ],
                            }),
                        ],
                        columnSpan: 3,
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun("Schule: "),
                                    new TextRun({ bold: true, text: parentState.md[1].value }),
                                ],
                            }),
                        ],
                        columnSpan: 6,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun("Zeit von "),
                                    new TextRun({ bold: true, text: (parentState.md[9].value.length > 0 ? parentState.md[9].value : "__:__") }),
                                    new TextRun(" bis "),
                                    new TextRun({ bold: true, text: (parentState.md[10].value.length > 0 ? parentState.md[10].value : "__:__") }),
                                    new TextRun(" Uhr"),
                                ],
                            }),
                        ],
                        columnSpan: 3,
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun("Klasse: "),
                                    new TextRun({ bold: true, text: parentState.md[2].value }),
                                ],
                            }),
                        ],
                        columnSpan: 3,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun("Thema: "),
                                    new TextRun({ bold: true, text: parentState.md[5].value }),
                                ],
                            }),
                        ],
                        columnSpan: 6,
                    }),
                ],
            }),
        ],
    });

    // guess starting here should be extracted to callback function
    const doc = new Document({
        creator: "Me, myself and I",
        title: "UBB Export docx Format",
        subject: "UBB Export (" + new Date().toLocaleString() + ")",
        description: "This is an export off the UBB Engine",
        customProperties: [
            { name: "Subtitle", value: "Subtitle1" },
            { name: "Address", value: "Here we are" },
        ],
        styles: {
            paragraphStyles: [
                {
                    id: 'Normal',
                    name: 'Normal',
                    run: {
                        font: 'Arial',
                        // size: 24, // Font size in half-points (e.g., 24 half-points = 12 points)
                        size: 20,
                    },
                },
            ],
        },
        sections: [
            {
                headers: {
                    default: new Header({
                        children: [
                            headerTable,
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Table({
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        alignment: AlignmentType.LEFT,
                                                        children: [
                                                            new TextRun({
                                                                // bold: true,
                                                                text: `[UBB-Export] von ${new Date().toLocaleString()} Uhr`,
                                                            }),
                                                        ],
                                                    }),
                                                ],
                                                borders: {
                                                    top: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                                                    bottom: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                                                    left: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                                                    right: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                                                },
                                                verticalAlign: 'center',
                                            }),

                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        alignment: AlignmentType.RIGHT,
                                                        children: [
                                                            new TextRun({
                                                                children: [
                                                                    "Seite ",
                                                                    PageNumber.CURRENT,
                                                                ],
                                                            }),
                                                        ],
                                                    }),
                                                ],
                                                borders: {
                                                    top: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                                                    bottom: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                                                    left: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                                                    right: { style: BorderStyle.NONE, size: 0, color: "ffffff" },
                                                },
                                                verticalAlign: 'center',
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            before: p_spacing_before,
                            after: p_spacing_after,
                        },
                        children: [
                            new TextRun({
                                text: "Kreisdarstellung",
                                // color: "FF0000",
                                bold: true,
                                size: 32,
                                // font: {
                                //     name: "Garamond",
                                // },
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,

                        children: [
                            new ImageRun({
                                // data: handleBase64Data(imageBase64Data),
                                data: pngData.split(",")[1],
                                transformation: {
                                    width: 570,
                                    height: 570,
                                },
                            }),
                        ],
                    }),
                    // new Paragraph({
                    //     alignment: AlignmentType.CENTER,
                    //     children: [
                    //         new TextRun({
                    //             text: "XXX Erklärung der Stufen",
                    //             color: "FF0000",
                    //         }),
                    //     ],
                    // }),
                ],
            },

            {
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            before: p_spacing_before,
                            after: p_spacing_after,
                        },
                        children: [
                            new TextRun({
                                text: "Notizen",
                                bold: true,
                                size: 32,
                            }),
                        ],
                    }),
                    ...paragraphs,
                ],
            },

            {
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            before: p_spacing_before,
                            after: p_spacing_after,
                        },
                        children: [
                            new TextRun({
                                text: "Tabellarische Ansicht",
                                bold: true,
                                size: 32,
                            }),
                        ],
                    }),
                    ubbTable,
                ],
            },
        ],
    });

    let saveName = fn;
    const blob = await Packer.toBlob(doc);

    if (type === 1) { // 1 => direct save
        fs.saveAs(blob, saveName);
    }
    else if (type === 2) { // 2 => file picker action
        const writable = await fpHandle.createWritable();
        await writable.write(blob);
        await writable.close();
    }

    if (!ubbChartDivAvailable) {
        const container = document.getElementById('ubbchart');
        // console.log("Unmounting donut chart");
        document.body.removeChild(container);
    }
    return saveName;

    // Canvases
    // const containerHC = document.createElement('div');
    // containerHC.style.position = 'absolute';
    // containerHC.style.top = '-9999px';
    // containerHC.style.left = '-9999px';
    // containerHC.id = 'ubbchart';
    // document.body.appendChild(containerHC);
    // const rootHC = ReactDOM.createRoot(containerHC);
    // rootHC.render(
    //     <HiddenCanvases parentState={parentState} onImagesGenerated={handleImagesGenerated} />
    // );
    // await new Promise(resolve => setTimeout(resolve, 1000)); // VERY ugly hack!
}
