// import logo from './logo.svg';
import React from 'react';
import { PureComponent } from 'react';
import ReactDOM from 'react-dom/client';

import './App.css';
import { handleExportRequestAll } from './Export.js';
import { handlePrintRequestAll } from './Print.js';

import { Routes, Route } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes, withRouter } from 'react-router-dom';

import Home from './pages/Home/Home';
import Imprint from './pages/Imprint/Imprint';
import DataPrivacy from './pages/DataPrivacy/DataPrivacy';
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Login from './pages/Login/Login';
import Toasts from './pages/Toasts/Toasts';
import NavbarTop from './pages/NavbarTop/NavbarTop';

import { Meta } from './pages/Meta/Meta';
import { Donut } from './pages/Donut/Donut';
import { TableUBB } from './pages/TableUBB/TableUBB';
import { Notes } from './pages/Notes/Notes';

// import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// import { Toast, ToastContainer, Button, Modal } from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';

import { ubb, default_toast_showtime, json_export_file_type } from "./pages/Consts.js"
// import Toast from 'react-bootstrap/Toast';
// import ToastContainer from 'react-bootstrap/ToastContainer';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';

// import { saveAs } from "file-saver";
import * as fs from "file-saver";
// import { Packer } from "docx";
// import { Document, Packer, Paragraph, TextRun } from "docx";
import { Document, Packer, Paragraph, TextRun } from "docx";
// import { Table, TableCell, TableRow, WidthType } from "docx";
// import { ImageRun } from "docx";
// import { md } from 'node-forge';

import { BrowserView, MobileView, isBrowser, isMobile, isDesktop } from 'react-device-detect';
import * as rdd from 'react-device-detect';

// import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.js";

// import * as d3 from "https://cdnjs.cloudflare.com/ajax/libs/d3/7.8.5/d3.min.js";

// import * as d3 from "d3";

const standardState = {
  type: "ubb_state_export",
  pWith: 2, // 0: text, 1: both, 2: pen
  dVis: [1, 1, 1],
  b: [
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },
    { bF: [0, 0, 0, 0, 0], text: "", canvas: { paths: [], strokeWidth: 2, eraserWidth: 8, eraserMode: false }, level: 0, visible: 0 },

  ],
  md: [
    { id: 1, name: "f_md_1", value: "" },
    { id: 2, name: "f_md_2", value: "" },
    { id: 3, name: "f_md_3", value: "" },
    { id: 4, name: "f_md_4", value: "" },
    { id: 5, name: "f_md_5", value: "" },
    { id: 6, name: "f_md_6", value: "" },
    { id: 7, name: "f_md_ta1", value: "" },
    { id: 8, name: "f_md_reason", value: "0" },
    { id: 9, name: "f_md_dstart", value: "" },
    { id: 10, name: "f_md_tstart", value: "" },
    { id: 11, name: "f_md_tend", value: "" },
  ],
  helper: {
    toasts: [
      // { id: 0, header: "Willkommen", body: "Hallo! Ich bin eine Info-Demo-Benachrichtigung und informiere Sie für sieben Sekunden.", showtime: 7000, timestamp: "jetzt", severity: "info", visible: true },
      // { id: 1, header: "Oha!", body: "Ich bin ein Warnung! Etwas muss wohl schief gelaufen sein... (nur drei Sekunden zu sehen!)", showtime: 3000, timestamp: "vorher", severity: "alert", visible: true },
      // { id: 1, header: "Toast Header 2", body: "Toast 2 Body", showtime: 4000, timestamp: "gleich", severity: "warning", visible: true },
      // { id: 2, header: "Toast Header 3", body: "Toast 3 Body", showtime: 6000, timestamp: "nie", severity: "alert", visible: true },
      // { id: 3, header: "Toast Header 4", body: "Toast 4 Body", showtime: 8000, timestamp: "spaeter", severity: "alert", visible: true },
    ],
    ctxMenu: {
      visible: false,
      x: 0,
      y: 0
    },
    modal: {
      header: "",
      body: "",
      footer_cancel: "Abbrechen",
      footer_confirm: "Bestätigen",
      method_confirm: null,
      visible: false,
    }
  },
  protocol: {
    data: "",
  },
}

class App extends PureComponent {

  // getCurrentRoute = () => {
  //   return this.props.location.pathname;
  // };

  goModal = (which) => {
    let mData = [];
    let fix = 0;

    if (which === 'reset') {
      mData.push("Die Anwendung zurücksetzen");
      mData.push("Bitte bestätigen Sie, dass Sie die Anwendung neu starten wollen. Ihr aktueller Bearbeitungsstand geht dabei verloren.");
      mData.push("Nein!");
      mData.push("Ja, zurücksetzen!");
      mData.push(this.resetState);
      mData.push(true);
      fix = 1;
    }
    else if (which === 'invalid_mobile_print_request') {
      mData.push("Kein Gesamt-Druck auf Mobilgeräten");
      mData.push("Sie verwenden ein Mobilgerät. Die kompakte Druckansicht ist nur auf PC und Laptop verfügbar. " +
        "Sie können jedoch die aktuelle Ansicht durcken. Möchten Sie das tun?");
      mData.push("Nein, danke.");
      mData.push("Ja, die aktuelle Ansicht drucken!");
      mData.push(this.handlePrintRequestMobile);
      mData.push(true);
      fix = 1;
    }
    else if (which === 'invalid_print_request') {
      mData.push("Keine Druckmöglichkeit");
      mData.push("Die aktuelle Seite bietet keine Druckmöglichkeit an. Bitte wechseln Sie zur Notizen-, Kreis- oder tabellarischen Ansicht.");
      mData.push("");
      mData.push("OK");
      mData.push(this.handleCancelModal);
      mData.push(true);
      fix = 1;
    }
    else if (which === 'invalid_export_request') {
      mData.push("Keine Exportmöglichkeit");
      mData.push("Die aktuelle Seite bietet keine Exportmöglichkeit an. Bitte wechseln Sie zur Notizen-, Kreis- oder tabellarischen Ansicht.");
      mData.push("");
      mData.push("OK");
      mData.push(this.handleCancelModal);
      mData.push(true);
      fix = 1;
    }
    else if (which === 'confirm_state_save_auto_filename') {
      const fn = this.genFilename(".json");
      mData.push("Bearbeitungsstand speichern");
      mData.push('Ihr Browser unterbindet das Anzeigen eines "Speichern unter..." Dialogs. ' +
        'Der aktuelle Bearbeitungsstand wird unter dem Dateinamen "' + fn +
        '" in den "Download"-Ordner gespeichert. Möchten Sie fortfahren?');
      mData.push("Abbrechen");
      mData.push("Ja, speichern!");
      mData.push((e) => this.handleDirectSaveExecute(fn));
      mData.push(true);
      fix = 1;
    }
    else if (which === 'confirm_state_export_auto_filename') {
      const fn = this.genFilename(".docx");
      mData.push("Bearbeitungsstand im .docx Format exportieren");
      mData.push('Ihr Browser unterbindet das Anzeigen eines "Speichern unter..." Dialogs. ' +
        'Der aktuelle Bearbeitungsstand wird im .docx Format unter dem Dateinamen "' + fn +
        '" in den "Download"-Ordner exportiert. Möchten Sie fortfahren?');
      mData.push("Abbrechen");
      mData.push("Ja, speichern!");
      mData.push((e) => this.handleDirectExportExecute(fn));
      mData.push(true);
      fix = 1;
    }

    if (fix !== 0) {
      this.setState(prevState => {
        let updatedItem = { ...prevState.helper.modal };
        updatedItem.header = mData[0];
        updatedItem.body = mData[1];
        updatedItem.footer_cancel = mData[2];
        updatedItem.footer_confirm = mData[3];
        updatedItem.method_confirm = mData[4];
        updatedItem.visible = mData[5];
        return { helper: { ...prevState.helper, modal: updatedItem } };
      })
    }
  }

  resetState = () => {
    // this.setState({ ...standardState });
    this.setState(JSON.parse(JSON.stringify(this.standardState)));
    // console.log(standardState);
  }

  handleCallbackCloseContextMenu = (e) => {
    // console.log("CLOSE CONTEXT MENU");
    this.setState(prevState => {
      let updatedItem = { ...prevState.helper.ctxMenu };
      updatedItem.visible = false;
      return { helper: { ...prevState.helper, ctxMenu: updatedItem } };
    });
  }

  handleCallbackContextMenu = (e) => {
    const menuWidth = 330;
    const menuHeight = 450;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    let x = e.clientX;
    let y = e.clientY;

    if ((x + menuWidth) > screenWidth) {
      x -= (x + menuWidth) - screenWidth;
    }
    if ((y + menuHeight) > screenHeight) {
      y -= (y + menuHeight) - screenHeight;
    }

    this.setState(prevState => {
      let updatedItem = { ...prevState.helper.ctxMenu };
      console.log(prevState.helper.ctxMenu);
      updatedItem.visible === true ? updatedItem.visible = false : updatedItem.visible = "true";
      updatedItem.x = x;
      updatedItem.y = y;
      return { helper: { ...prevState.helper, ctxMenu: updatedItem } };
    });
  }

  async genFileNamePickerDialogExport() {
    const fn = this.genFilename(".docx");
    const opts = {
      suggestedName: fn,
      types: [{
        description: 'DOCX Datei',
        accept: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] },
      }],
    };

    try {
      const handle = await window.showSaveFilePicker(opts);
      const saveName = await handleExportRequestAll({ parentState: this.state, fn: fn, type: 2, fpHandle: handle });

      this.injectToast("Bearbeitungsstand im .docx Format exportiert.",
        // "Der aktuelle Bearbeitungsstand wurde unter dem Dateinamen '" + handle.name +
        "Der aktuelle Bearbeitungsstand wurde unter dem Dateinamen '" + saveName +
        "' exportiert.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "info",
        true);
    }
    catch (err) {
      console.error("Error exporting file:", err);
      this.injectToast("Export abgebrochen",
        "Der aktuelle Bearbeitungsstand wurde nicht exportiert.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "warning",
        true);
    }
  }

  handleSave = () => {
    // const textFile = new Blob([JSON.stringify(this.state)], { type: 'text/plain' });
    const saveState = {
      ...this.state,
      helper: { ...standardState.helper }
    };

    const textFile = new Blob([JSON.stringify(saveState)], { type: 'application/json' });

    if (window.showSaveFilePicker) {
      this.genFileNamePickerDialog(textFile);
    }
    else {
      this.handleDirectSave();
    }
  }

  async genFileNamePickerDialog(workLoad) {
    const opts = {
      suggestedName: this.genFilename(".json"),
      types: [{
        description: 'JSON Datei',
        accept: { 'application/json': ['.json'] },
      }],
    };

    try {
      const handle = await window.showSaveFilePicker(opts);

      const writable = await handle.createWritable();
      await writable.write(workLoad);
      await writable.close();
      this.injectToast("Bearbeitungsstand gespeichert",
        "Der aktuelle Bearbeitungsstand wurde unter dem Dateinamen '" + handle.name +
        "' auf Ihrem lokalen Dateisystem gespeichert.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "info",
        true);
    }
    catch (err) {
      this.injectToast("Speichern abgebrochen",
        "Der aktuelle Bearbeitungsstand wurde nicht gespeichert.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "warning",
        true);
    }
  }

  handleDirectExport = () => {
    this.goModal('confirm_state_export_auto_filename');
  }

  handleDirectExportExecute = async (fn) => {
    this.handleCancelModal();
    console.log("Exporting to file:", fn);

    try {
      const saveName = await handleExportRequestAll({ parentState: this.state, fn: fn, type: 1 });
      this.injectToast("Bearbeitungsstand im .docx Format exportiert.",
        "Der aktuelle Bearbeitungsstand wurde unter dem Dateinamen '" + saveName +
        "' exportiert.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "info",
        true);
    }
    catch (e) {
      this.injectToast("Export fehlgeschlagen",
        "Der aktuelle Bearbeitungsstand wurde nicht exportiert.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "warning",
        true);
    }
  }

  handleDirectSave = () => {
    this.goModal('confirm_state_save_auto_filename');
  }

  handleDirectSaveExecute = (fn) => {
    this.handleCancelModal();
    try {
      // const textFile = new Blob([JSON.stringify(this.state)], { type: 'text/plain' });
      const saveState = {
        ...this.state,
        helper: { ...standardState.helper }
      };
      const textFile = new Blob([JSON.stringify(saveState)], { type: 'application/json' });

      let dlFileName = fn;

      fs.saveAs(textFile, dlFileName);

      this.injectToast("Bearbeitungsstand gespeichert",
        "Der aktuelle Bearbeitungsstand wurde unter dem Dateinamen '" + dlFileName +
        "' auf Ihrem lokalen Dateisystem gespeichert.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "info",
        true);
    } catch (error) {
      console.error("Error saving file:", error);
      this.injectToast("Speichern fehlgeschlagen",
        "Der aktuelle Bearbeitungsstand konnte nicht gespeichert werden.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "alert",
        true);
    }
  };

  genFormattedDateString() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}${month}${day}-${hours}${minutes}`;
  }

  handlePrintRequestMobile = () => {
    this.handleCancelModal();
    window.print();
  }

  genFilename(suffix) {
    // var invalid_chars = /[^a-zA-Z0-9_-]/g;

    return "ubb-bearbeitungsstand-" + this.genFormattedDateString() + suffix;
    // const fn = new Date().toISOString().slice(0, 10) + "-Analyse" + "-" +
    //   this.props.parentState.klassenarbeiten[0].schuljahr.replace(invalid_chars, "") + "-" +
    //   this.props.parentState.klassenarbeiten[0].fach.replace(invalid_chars, "") + ".json";
  }

  // handleFileUploadChange = (e, x) => {
  handleFileUploadChange = (e) => {
    e.preventDefault();
    const x = this;
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const file = files[0];
      if (file.type.startsWith("application/json")) {
        const reader = new FileReader();
        reader.onloadend = (function (theFile) {
          return function (e) {
            if (e.target.readyState === FileReader.DONE) {
              const fContent = JSON.parse(e.target.result);
              console.log(fContent);
              if (fContent.hasOwnProperty("type") && fContent["type"] === json_export_file_type) {
                x.setState(() => {
                  const newState = fContent;
                  return newState;
                });
                // x.forceUpdate();
                // console.log("Bearbeitungsstand aus Datei " + file.name + " geladen.");
                x.injectToast("Bearbeitungsstand geladen",
                  "Die von Ihnen ausgewählte Datei '" + file.name + "' wurde geladen.",
                  default_toast_showtime,
                  new Date().toLocaleString(),
                  "info",
                  true);
              }
              else {
                x.injectToast("Falscher Dateityp",
                  "Die von Ihnen ausgewählte Datei '" + file.name + "' hat nicht den korrekten Dateityp. " +
                  "Sie können nur aus dieser Anwendung exportierte Bearbeitungsstände laden.",
                  default_toast_showtime,
                  new Date().toLocaleString(),
                  "warning",
                  true);
              }
            }
          };
        })(file);
        reader.readAsText(file);
      }
      else {
        x.injectToast("Falscher Dateityp",
          "Die von Ihnen ausgewählte Datei '" + file.name + "' hat nicht den korrekten Dateityp. " +
          "Sie können nur aus dieser Anwendung exportierte Bearbeitungsstände laden.",
          default_toast_showtime,
          new Date().toLocaleString(),
          "warning",
          true);
      }

      e.target.value = "";
      // x.forceUpdate();
    }
    else {
      console.log("Keine Datei ausgewählt.");
    }
  }

  handlePrintRequestAll = async () => {
    // testing safari XXX
    // rdd.isMobile = true;
    // rdd.isBrowser = false;
    // rdd.isDesktop = false;

    if (isDesktop) {
      try {
        const loc = window.location.pathname; // ugly hack XXX
        // console.log("HI FROM APP handlePrintRequestAll");
        // const dingsi = await handlePrintRequestAll({ parentState: this.state, loc: loc });
        await handlePrintRequestAll({ parentState: this.state, loc: loc });
        // this.injectToast("Druckvorgang gestartet",
        //   "Der Druckdialog wurde gestartet.",
        //   default_toast_showtime,
        //   new Date().toLocaleString(),
        //   "info",
        //   true);
      }
      catch (e) {
        console.log("Error:", e);
        console.log("Error printing");
      }
    }
    else {
      this.goModal('invalid_mobile_print_request');
    }
  }

  handleCallbackHandleExport = () => {
    if (window.showSaveFilePicker) {
      this.genFileNamePickerDialogExport();
    }
    else {
      this.handleDirectExport();
    }
  }

  handleExportRequestAll = async () => {
    try {
      const saveName = await handleExportRequestAll({ parentState: this.state });
      this.injectToast("Notizen exportiert",
        "Die Notizen wurde unter dem Dateinamen '" + saveName + "' auf Ihrem lokalen Dateisystem gespeichert.",
        // XXX notizen?
        default_toast_showtime,
        new Date().toLocaleString(),
        "info",
        true);
    }
    catch (e) {
      console.log("Error:", e);
      console.log("Error saving file");
      // XXX add inject toast (!)
    }
  }

  handleExportRequest = () => {
    const loc = window.location.pathname; // ugly hack!!!!!!!
    // if (loc === "/ubb/donut") {
    if (loc === "/donut") {
      const svgElement = document.getElementById('ubbchart').querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {

        let saveName = this.genFilename() + '_donut.png';
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const imgURL = canvas.toDataURL('image/png');
        const dlLink = document.createElement('a');
        dlLink.download = saveName;
        dlLink.href = imgURL;
        dlLink.dataset.downloadurl = ['image/png', dlLink.download, dlLink.href].join(':');
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);

        this.injectToast("Kreisdarstellung exportiert",
          "Die Kreisdarstellung wurde unter dem Dateinamen '" + saveName + "' auf Ihrem lokalen Dateisystem gespeichert.",
          default_toast_showtime,
          new Date().toLocaleString(),
          "info",
          true);
      };

      // const style = `
      //     body {
      //         font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      //         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      //         sans-serif;
      //     }
      // `;

      // const svgBlob = new Blob([`<style>${style}</style>${svgData}`], { type: 'image/svg+xml;charset=utf-8' });
      const svgBlob = new Blob([`${svgData}`], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(svgBlob);
      img.src = url;
    }
    // else if (loc === "/ubb/table") {
    else if (loc === "/table") {
      this.injectToast("Export der tabellarischen Ansicht noch nicht möglich",
        "Die aktuelle Version bietet noch keinen Export der tabellarischen Ansicht.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "warning",
        true);
    }
    // else if (loc === "/ubb/protocol") {
    else if (loc === "/notes") {
      const paragraphs = this.state.protocol.data.split('\n\n').map(paragraphText => {
        return new Paragraph({
          children: [
            new TextRun(paragraphText)
          ]
        });
      });

      const doc = new Document({
        sections: [
          {
            properties: {},
            children: paragraphs,
          },
        ],
      });

      let saveName = this.genFilename() + "notizen.docx";
      Packer.toBlob(doc).then(blob => {
        console.log(blob);
        fs.saveAs(blob, saveName);
      });

      this.injectToast("Notizen exportiert",
        "Die Notizen wurde unter dem Dateinamen '" + saveName + "' auf Ihrem lokalen Dateisystem gespeichert.",
        default_toast_showtime,
        new Date().toLocaleString(),
        "info",
        true);
    }
    else {
      this.goModal('invalid_export_request');
    }
  }

  handlePrintRequest = () => {
    const loc = window.location.pathname; // ugly hack!!
    // if (loc === "/ubb/donut") {
    if (loc === "/donut") {
      console.log("PRINT REQUEST FROM DONUT");
      const svgElement = document.getElementById('ubbchart').innerHTML;
      const printWindow = window.open('', '_blank');

      const style = `
            body {
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif;
            }
        `;

      printWindow.document.open();
      printWindow.document.write(`
            <html>
                <head>
                    <style>${style}</style>
                </head>
                <body>
                    <div>${svgElement}</div>
                </body>
            </html>
        `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
    // else if (loc === "/ubb/table") {
    else if (loc === "/table") {
      console.log("PRINT REQUEST FROM TABLE");
      // const svgElement = document.getElementById('ubbchart').innerHTML;
      // const printWindow = window.open('', '_blank');

      // const style = `
      //       body {
      //           font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      //           'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      //           sans-serif;
      //       }
      //   `;

      let tableToPrint = <TableUBB
        parentState={this.state}
        ubb={ubb}
        backCallerChangeLevelRadio={this.handleCallbackChangeLevelRadio}
        backCallerChangeTextData={this.handleCallbackChangeTextData}
        backCallerChangeBFSwitches={this.handleCallbackChangeBFSwitches}
        backCallerChangeSavePathsToState={this.handleCallbackChangeSavePathsToState}
        backCallerChangeBVisibility={this.handleCallbackChangeBVisibility}
        backCallerChangeDVisibility={this.handleCallbackChangeDVisibility}
        backCallerChangeStrokeWidth={this.handleCallbackChangeStrokeWidth}
        backCallerChangeEraserWidth={this.handleCallbackChangeEraserWidth}
        backCallerChangeEraserMode={this.handleCallbackChangeEraserMode}
        backCallerEraseCanvas={this.handleCallbackEraseCanvas}
      />;
      const printContent = document.getElementById('printArea');

      const root = ReactDOM.createRoot(printContent);
      root.render(tableToPrint);

      window.print();
      // ReactDOM.unmountComponentAtNode(printContent);
      root.unmount();
      // });
    }
    // else if (loc === "/ubb/protocol") {
    else if (loc === "/protocol") {
      console.log("PRINT REQUEST FROM PROTOCOL");
      window.print();
    }
    else {
      this.goModal('invalid_print_request');
    }
  }

  handleCallbackChangeBVisibility = (bIdx) => {
    setTimeout(() => {
      this.setState(prevState => {
        let updatedItem = { ...prevState.b[bIdx] };
        updatedItem.visible === 0 ? updatedItem.visible = 1 : updatedItem.visible = 0;
        let updatedB = prevState.b.map((item, idx) => idx === bIdx ? updatedItem : item);
        return { b: updatedB };
      });
    }, 250);
  }

  handleCallbackChangeDVisibility = (dIdx) => {
    // console.log("Shozld change visibility of D", dIdx);
    let dV = this.state.dVis;
    // console.log(dV);
    dV[dIdx] === 0 ? dV[dIdx] = 1 : dV[dIdx] = 0;
    setTimeout(() => {
      this.setState({ dVis: dV });
    }, 250);
    //   this.setState(prevState => {
    //     let updatedItem = { ...prevState.b[bIdx] };
    //     updatedItem.visible === 0 ? updatedItem.visible = 1 : updatedItem.visible = 0;
    //     let updatedB = prevState.b.map((item, idx) => idx === bIdx ? updatedItem : item);
    //     return { b: updatedB };
    //   });
    // }, 250);
  }

  handleCallbackChangeLevel = (e) => {
    const id = e.target.id.split("_");
    let index = Number(id[1]) - 1;
    let val = id[2];

    // console.log("XXX >>> " + this.state.b[index].level + " -> " + val);
    // console.log("XXX >>> " + typeof(this.state.b[index].level) + " -> " + typeof(val));

    // out because hassle with popovers on donut XXX
    if (this.state.b[index].level !== val) {

      // this.injectToast("Änderung der Ebene",
      //   "'" + ubb.beobachtungsfelder[index].name + "' auf Stufe " + val + " gesetzt.",
      //   // default_toast_showtime,
      //   2000,
      //   new Date().toLocaleString(),
      //   "info",
      //   true);

      this.setState(prevState => {
        let updatedItem = { ...prevState.b[index] };
        updatedItem.level = Number(val);
        let updatedB = prevState.b.map((item, idx) => idx === index ? updatedItem : item);
        return { b: updatedB };
      });
      this.forceUpdate();
    }
  }

  handleCallbackEraseCanvas = (index) => {
    this.setState(prevState => {
      let updatedItem = { ...prevState.b[index] };
      updatedItem.canvas.paths = [];
      let updatedB = prevState.b.map((item, idx) => idx === index ? updatedItem : item);
      return { b: updatedB };
    });
  }

  handleCallbackChangeEraserMode = (index, val) => {
    // console.log(index, val);
    this.setState(prevState => {
      let updatedItem = { ...prevState.b[index] };
      updatedItem.canvas.eraserMode = val;
      let updatedB = prevState.b.map((item, idx) => idx === index ? updatedItem : item);
      return { b: updatedB };
    });
  }

  handleCallbackChangeStrokeWidth = (e) => {
    const id = e.target.id.split("_");
    let bIdx = Number(id[1]);
    let val = Number(e.target.value);
    this.setState(prevState => {
      let updatedItem = { ...prevState.b[bIdx] };
      updatedItem.canvas.strokeWidth = val;
      let updatedB = prevState.b.map((item, idx) => idx === bIdx ? updatedItem : item);
      return { b: updatedB };
    });
  }

  handleCallbackChangeEraserWidth = (e) => {
    const id = e.target.id.split("_");
    let bIdx = Number(id[1]);
    let val = Number(e.target.value);
    this.setState(prevState => {
      let updatedItem = { ...prevState.b[bIdx] };
      updatedItem.canvas.eraserWidth = val;
      let updatedB = prevState.b.map((item, idx) => idx === bIdx ? updatedItem : item);
      return { b: updatedB };
    });
  }

  handleCallbackChangeSavePathsToState = (p, bIdx) => {
    if (p.length > 0) {
      this.setState(prevState => {
        let updatedItem = { ...prevState.b[bIdx] };
        updatedItem.canvas.paths = p;
        let updatedB = prevState.b.map((item, idx) => idx === bIdx ? updatedItem : item);
        return { b: updatedB };
      });
    }
  }

  handleCallbackChangeBFSwitchesColor = (e, bIdx, fIdx, c) => {
    const type = e.target.type;
    if (type === "button") {
      this.setState(prevState => {
        // let updatedItem = { ...prevState.b[bIdx].bF[fIdx] };
        let updatedItem = { ...prevState.b[bIdx] };
        console.log(updatedItem);
        // updatedItem.bF[fIdx] === 0 ? updatedItem.bF[fIdx] = 1 : updatedItem.bF[fIdx] = 0;
        updatedItem.bF[fIdx] = c;
        console.log(updatedItem);
        let updatedB = prevState.b.map((item, idx) => idx === bIdx ? updatedItem : item);
        return { b: updatedB };
      });
    }

    // console.log(e.target);
    // console.log(bIdx);
    // console.log(fIdx);
    // console.log(c);

  }

  handleCallbackChangeBFSwitches = (e) => {
    // console.log(e.target);
    // const name = e.target.name;
    const type = e.target.type;
    // let value = e.target.value;

    if (type === "checkbox") {
      const id = e.target.id.split("_");
      let bIdx = Number(id[1]);
      let fIdx = Number(id[2]);
      // console.log(bIdx, fIdx);
      // console.log(this.state.b[bIdx]);
      // console.log(this.state.b[bIdx].bF[fIdx]);
      this.setState(prevState => {
        // let updatedItem = { ...prevState.b[bIdx].bF[fIdx] };
        let updatedItem = { ...prevState.b[bIdx] };
        console.log(updatedItem);
        updatedItem.bF[fIdx] === 0 ? updatedItem.bF[fIdx] = 1 : updatedItem.bF[fIdx] = 0;
        console.log(updatedItem);
        let updatedB = prevState.b.map((item, idx) => idx === bIdx ? updatedItem : item);
        return { b: updatedB };
      });
    }
  }

  handleCallbackChangeLevelRadio = (e) => {
    // const name = e.target.name;
    const type = e.target.type;
    // console.log(e.target)
    // let value = e.target.value;

    if (type === "radio") {
      const id = e.target.id.split("_");
      let index = Number(id[1]);
      let val = id[2];

      // this.injectToast("Änderung der Ebene",
      //   "'" + ubb.beobachtungsfelder[index].name + "' auf Stufe " + val + " gesetzt.",
      //   default_toast_showtime,
      //   new Date().toLocaleString(),
      //   "info",
      //   true);

      this.setState(prevState => {
        let updatedItem = { ...prevState.b[index] };
        updatedItem.level = Number(val);
        // console.log(updatedItem);
        let updatedB = prevState.b.map((item, idx) => idx === index ? updatedItem : item);
        return { b: updatedB };
      });
    }
  }

  handleCallbackChangeProtocolData = (e) => {
    // const name = e.target.name;
    // const type = e.target.type;
    let value = e.target.value;

    this.setState(prevState => {
      let updatedItem = { ...prevState.protocol };
      updatedItem.data = value;
      return { protocol: updatedItem };
    });
  }

  injectToast = (header, body, showtime, timestamp, severity, visible) => {

    this.setState(prevState => {
      const updatedToasts = prevState.helper.toasts;
      const tLen = updatedToasts.length;

      const newToast = {
        id: tLen + 1,
        header: header,
        body: body,
        showtime: showtime,
        // timestamp: new Date().toLocaleString(),
        timestamp: timestamp,
        severity: severity,
        visible: visible,
      };

      updatedToasts.push(newToast);
      return { helper: { ...prevState.helper, toasts: updatedToasts } };
    });

  }

  handleCallbackCopyData = (id, data) => {
    // console.log("text to add ", data);
    // console.log("where to add", id);

    if (data.length === 0) {
      this.injectToast("Keine Kopie erstellt",
        "Es wurde kein Text ausgewählt.", default_toast_showtime,
        new Date().toLocaleString(), "info", true);
    }
    else {
      let index = id;

      let bFName = ubb.beobachtungsfelder[index].name;
      this.injectToast("Text kopiert",
        "Der von Ihnen ausgewählte Text wurde nach '" + bFName + "' kopiert",
        default_toast_showtime, new Date().toLocaleString(), "info", true);

      this.setState(prevState => {
        let updatedItem = { ...prevState.b[index] };
        if (updatedItem.text.length > 0) {
          updatedItem.text += "\n\n";
        }
        updatedItem.text += data;
        // console.log(updatedItem);
        let updatedB = prevState.b.map((item, idx) => idx === index ? updatedItem : item);
        return { b: updatedB };
      });
    }
  }

  handleCallbackChangeMetaData = (e) => {
    // const name = e.target.name;
    const type = e.target.type;
    let value = e.target.value;

    if (type === "text") {
      const id = e.target.id.split("_");
      let index = Number(id[2]) - 1;
      let val = e.target.value;
      this.setState(prevState => {
        let updatedItem = { ...prevState.md[index] };
        updatedItem.value = val;
        let updatedB = prevState.md.map((item, idx) => idx === index ? updatedItem : item);
        return { md: updatedB };
      });
    }
    if (type === "textarea") {
      // const id = e.target.id.split("_");
      let index = 6; // dirty hack
      let val = e.target.value;

      this.setState(prevState => {
        let updatedItem = { ...prevState.md[index] };
        updatedItem.value = val;
        let updatedB = prevState.md.map((item, idx) => idx === index ? updatedItem : item);
        return { md: updatedB };
      });
    }
    if (type === "select-one") {
      // const id = e.target.id.split("_");
      let index = 7; // dirty hack
      let val = e.target.value;

      this.setState(prevState => {
        let updatedItem = { ...prevState.md[index] };
        updatedItem.value = val;
        let updatedB = prevState.md.map((item, idx) => idx === index ? updatedItem : item);
        return { md: updatedB };
      });
    }
    if (type === "date") {
      // const id = e.target.id.split("_");
      let index = 8; // dirty hack
      this.setState(prevState => {
        let updatedItem = { ...prevState.md[index] };
        updatedItem.value = value;
        let updatedB = prevState.md.map((item, idx) => idx === index ? updatedItem : item);
        return { md: updatedB };
      });
    }
    if (type === "time") {
      const id = e.target.id.split("_");
      let index = (id[2] === "tstart") ? 9 : 10;
      this.setState(prevState => {
        let updatedItem = { ...prevState.md[index] };
        updatedItem.value = value;
        let updatedB = prevState.md.map((item, idx) => idx === index ? updatedItem : item);
        return { md: updatedB };
      });
    }
  }

  handleCallbackChangeTextData = (e) => {
    // const name = e.target.name;
    // let value = e.target.value;
    const type = e.target.type;

    if (type === "textarea") {
      const id = e.target.id.split("_");
      let index = Number(id[1]);
      let val = e.target.value;

      this.setState(prevState => {
        let updatedItem = { ...prevState.b[index] };
        updatedItem.text = val;
        console.log(updatedItem);
        let updatedB = prevState.b.map((item, idx) => idx === index ? updatedItem : item);
        return { b: updatedB };
      });
    }
  }

  handleCallbackChangePWith = (to) => {
    if (this.state.pWith === to) {
      return;
    }

    this.setState((prevState) => ({
      pWith: to,
    }));

    const mode = (to === 2) ? "Stifteingabe" : "Tastatureingabe";

    this.injectToast("Bearbeitungsmodus geändert",
      "Sie haben die '" + mode + "' aktiviert.",
      // default_toast_showtime,
      2500,
      new Date().toLocaleString(),
      "info",
      true);
  }

  constructor(props) {
    super(props);

    this.state = JSON.parse(JSON.stringify(standardState));
    this.standardState = JSON.parse(JSON.stringify(standardState));

    this.handleUnload = this.handleUnload.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
    window.addEventListener('unload', this.handleUnload); // not sure, if this one is needed
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
    window.removeEventListener('unload', this.handleUnload);
  }

  handleUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  }

  toggleToast = (id) => {
    this.setState(prevState => {
      const updatedToasts = prevState.helper.toasts.map(toast => {
        if (toast.id === id) {
          return { ...toast, visible: !toast.visible };
        }
        return toast;
      });
      return { helper: { ...prevState.helper, toasts: updatedToasts } };
    });
  }

  handleCancelModal = () => {
    this.setState(prevState => {
      let updatedItem = { ...prevState.helper.modal };
      updatedItem.visible = false;
      return { helper: { ...prevState.helper, modal: updatedItem } };
    });

  }

  handleRadioChange = () => {
    // No-op function to satisfy React's requirement
  };

  handleCallbackToggleToast = (id) => {
    this.toggleToast(id);
  }

  render() {
    // console.log(this.state);
    // const loc = window.location.pathname; // ugly hack!!
    // console.log(loc)
    return (
      <>
        <div id="printAreaHeader" style={{ display: "none" }} />

        {/* <nav className="no-print navbar navbar-expand-xl fixed-top nbBackGround"
          aria-label="UBB Navigationsleiste">
          <div className="container-fluid">

            <Link className="navbar-brand nav-link" to="/">
              <img id="headerImageLion" src={`${process.env.PUBLIC_URL}/bw2.png`} alt="Kultusministerium Baden-Württemberg"
                title="Kultusministerium Baden-Württemberg" />
            </Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#ubbnavbar" aria-controls="ubbnavbar" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="ubbnavbar">
              <ul className="navbar-nav me-auto mb-2 mb-md-0">

                <li id="dd_1" className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Bearbeitungsstand</a>
                  <ul className="dropdown-menu">

                    <li id="dd_menu_1">
                      <a className="dropdown-item" href="#" style={{ padding: '0' }}>
                        <label htmlFor="inputFileUpload" style={{
                          display: 'block',
                          width: '100%',
                          cursor: 'pointer',
                          padding: '4px 16px',
                        }}>
                          Laden
                          <input id="inputFileUpload" type="file" style={{ display: 'none' }} onChange={e => this.handleFileUploadChange(e, this)} />
                        </label>
                      </a>
                    </li>

                    <li id="dd_menu_2"><a className="dropdown-item" href="#"
                      onClick={(e) => this.handleSave()}>Speichern</a></li>
                    <li id="dd_menu_3"><hr className="dropdown-divider" /></li>
                    <li id="dd_menu_4"><a className="dropdown-item" href="#"
                      onClick={(e) => this.handlePrintRequestAll()}>Drucken</a></li>
                    <li id="dd_menu_5"><hr className="dropdown-divider" /></li>
                    <li id="dd_menu_6"><a className="dropdown-item" href="#"
                      onClick={(e) => this.handleExportRequestAll()}>Exportieren</a></li>
                    <li id="dd_menu_7"><hr className="dropdown-divider" /></li>
                    <li id="dd_menu_8"><a className="dropdown-item" href="#"
                      onClick={(e) => this.goModal('reset')}>Zurücksetzen</a></li>
                  </ul>
                </li>

                <li id="dd_2" className="nav-item">
                  <Link className="nav-link" to="/meta">Metadaten</Link>
                </li>
                <li id="dd_3" className="nav-item">
                  <Link className="nav-link" to="/notes">Notizen</Link>
                </li>
                <li id="dd_4" className="nav-item">
                  <Link className="nav-link" to="/donut">Kreisdarstellung</Link>
                </li>
                <li id="dd_5" className="nav-item">
                  <Link className="nav-link" to="/table">Tabellarische Ansicht</Link>
                </li>
                <li id="dd_6" className="nav-item">
                  <Link className="nav-link" to="/data_privacy">Datenschutz</Link>
                </li>
                <li id="dd_6" className="nav-item">
                  <Link className="nav-link" to="/imprint">Impressum</Link>
                </li>
              </ul>

            </div>
          </div>
        </nav> */}

        <NavbarTop
          parentState={this.state}
          backCallerGoModal={this.goModal}
          backCallerHandleExportRequestAll={this.handleExportRequestAll}
          backCallerHandlePrintRequestAll={this.handlePrintRequestAll}
          backCallerHandleSave={this.handleSave}
          backCallerHandleFileUploadChange={this.handleFileUploadChange}
          backCallerHandleCallbackChangePWith={this.handleCallbackChangePWith}
          backCallerHandleExport={this.handleCallbackHandleExport}
        />

        {/* <div className="navbar-nav switch-toggle switch-3 switch-candy d-flex">
                <input id="text" name="rViewState" type="radio"
                  checked={this.state.pWith === 0}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="text" onClick={(e) => this.viewChanged(0)}>Text</label>
                <input id="both" name="rViewState" type="radio"
                  checked={this.state.pWith === 1}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="both" onClick={(e) => this.viewChanged(1)}>Beides</label>
                <input id="pen" name="rViewState" type="radio"
                  checked={this.state.pWith === 2}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="pen" onClick={(e) => this.viewChanged(2)}>Stift</label>
              </div> */}

        <div className="App mt-3">

          {/* <header className="App-header"> */}
          {/* <header>
            void
          </header>
 */}
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/donut" element={<Donut
                parentState={this.state}
                ubb={ubb}
                backCallerHandleChangeLevel={this.handleCallbackChangeLevel}
              />} />
              <Route path="/table" element={<TableUBB
                parentState={this.state}
                ubb={ubb}
                backCallerChangeLevelRadio={this.handleCallbackChangeLevelRadio}
                backCallerChangeTextData={this.handleCallbackChangeTextData}
                backCallerChangeBFSwitchesColor={this.handleCallbackChangeBFSwitchesColor}
                backCallerChangeBFSwitches={this.handleCallbackChangeBFSwitches}
                backCallerChangeSavePathsToState={this.handleCallbackChangeSavePathsToState}
                backCallerChangeBVisibility={this.handleCallbackChangeBVisibility}
                backCallerChangeDVisibility={this.handleCallbackChangeDVisibility}
                backCallerChangeStrokeWidth={this.handleCallbackChangeStrokeWidth}
                backCallerChangeEraserWidth={this.handleCallbackChangeEraserWidth}
                backCallerChangeEraserMode={this.handleCallbackChangeEraserMode}
                backCallerEraseCanvas={this.handleCallbackEraseCanvas}
              />} />
              <Route path="/data_privacy" element={<DataPrivacy />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/login" element={<Login />} />
              <Route path="/meta" element={<Meta
                parentState={this.state}
                backCallerChangeMetaData={this.handleCallbackChangeMetaData}
              />} />
              <Route path="/notes" element={<Notes
                parentState={this.state}
                ubb={ubb}
                backCallerContextMenu={this.handleCallbackContextMenu}
                backCallerCloseContextMenu={this.handleCallbackCloseContextMenu}
                backCallerChangeProtocolData={this.handleCallbackChangeProtocolData}
                backCallerCopyData={this.handleCallbackCopyData}
              />} />
              <Route path="*" element={<PageNotFound />} />

            </Routes>

            <div className="no-print">
              <Toasts
                parentState={this.state}
                backCallerHandleToggleToast={this.handleCallbackToggleToast}
              />
            </div>
          </main>
        </div>

        <div className="no-print">
          <Modal show={this.state.helper.modal.visible}
            onHide={this.handleCancelModal}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.helper.modal.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.helper.modal.body}</Modal.Body>
            <Modal.Footer>
              {(this.state.helper.modal.footer_cancel.length > 0) && (
                <>
                  <Button variant="secondary" onClick={this.handleCancelModal}>
                    {this.state.helper.modal.footer_cancel}
                  </Button>
                </>
              )}
              <Button variant="primary" onClick={this.state.helper.modal.method_confirm}>
                {this.state.helper.modal.footer_confirm}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div id="printArea" style={{ display: 'none' }}></div>
      </>
    )
  };
}

window.addEventListener("unhandledrejection", (event) => {
  console.warn('PROMISE REJECTION: ' + event.reason);
  event.preventDefault();
});

// const toastElList = document.querySelectorAll('.toast')
// const toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl, { autohide: false }));

export default App;
// export default withRouter(App);
