import Button from 'react-bootstrap/Button';
import './Home.css';

const packageJson = require('../../../package.json');

const Home = ({ parentState, backCallerHandleCancelModalAreYouSure }) => {
    return (
        <>
            {/* <h2>Herzlich willkommen zum Webformular zum Basismodell</h2> */}
            <div className="start-screen">
                <img src={`${process.env.PUBLIC_URL}/ubb_title.png`}
                    alt="Basismodell für die Unterrichtsbeobachtung und-bewertung an beruflichen Schulen"
                    className="start-screen-image" />
            </div>

            <Button variant="outline-dark" className="p-1 my-3 no-print" target="_blank"
                href="https://llpa.kultus-bw.de/site/pbs-bw-km-root/get/documents_E1343567510/KULTUS.Dachmandant/KULTUS/Dienststellen/llpa-bw/LLPA%20Karlsruhe/Berufliche%20Schulen/Vordrucke%20und%20Unterlagen%20f%C3%BCr%20die%20unterrichtspraktische%20Pr%C3%BCfung%20%28UPP%29%20-%20alle%20Ausbildungsarten/KM%20Basismodell%203.%20Auflage.pdf">
                Hier können Sie die aktuelle Handreichung im Internet aufrufen.</Button>

            <div
                className="position-fixed bottom-0 end-0 m-2 px-2 bg-dark text-white opacity-75 rounded"
                style={{ fontSize: '12px', width: 'auto', textAlign: 'right' }}
            >
                Version: {packageJson.version}
            </div>
        </>
    )
}

export default Home;