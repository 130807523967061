import { PureComponent } from 'react'

export class ErrorBoundary extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            theError: "",
            errorInfo: {},
        };
    }

    componentDidCatch(error, errorInfo) {
        // console.log({ error, errorInfo });
        this.setState({
            theError: error,
            errorInfo: errorInfo,
        })
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        // console.log("Something wonderful has happened: " + error);
        return {
            theError: error,
            hasError: true,
        };
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <div className="errorBoundaryDiv">
                        <h2 className="clearAll">We are terribly sorry, Master!</h2>
                        <p>
                            Das System ist erfolgreich, selbstsicher, ohne vorige Absprache, aber mit W&uuml;rde&nbsp;
                            <span className="bold">abgest&uuml;rzt.</span> Das tut uns <span className="bold">sehr</span> leid!
                        </p>
                        <h3>Fehlermeldung:</h3>
                        <pre className="borderMe">
                            {this.state.errorInfo.componentStack}
                        </pre>
                        <p>
                            Wir m&ouml;chten Sie bitten, sich mit uns in Verbindung zu setzen. Bitte beschreiben Sie den Vorgang, 
                            der zu dem Fehler geführt hat, m&ouml;glichst genau. Bitte senden Sie auch obige Fehlermeldung 
                            (roter Rahmen) mit in Ihrer
                            Mitteilung. Das w&uuml;rde uns und dem gesamten Projekt sehr helfen.
                        </p>
                        <h3>Vielen Dank f&uuml;r Ihre Mithilfe.</h3>
                    </div>
                </>
            )
        }
        else {
            return this.props.children;
        }

    }
}