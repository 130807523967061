import React from 'react';
import { PureComponent, createRef } from 'react';
// import { ButtonGroup, ToggleButton } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
// import { ReactSketchCanvas, ReactSketchCanvasRef } from "react-sketch-canvas";
import { ReactSketchCanvas } from "react-sketch-canvas";

import "./TableUBB.css";

export class TableUBB extends PureComponent {

    scribbleRefs = [...Array(9).keys()].map(x => createRef());

    canvasClear = (ref, index) => {
        if (window.confirm('Wollen Sie wirklich das gesamte Stiftfeld leeren?')) {
            // ref.current?.clearCanvas();
            this.props.backCallerEraseCanvas(index);
            ref.current?.resetCanvas();
        }
    }

    canvasUndoRedo = (ref, undo) => {
        (undo) ? ref.current?.undo() : ref.current?.redo();
        // console.log(ref.current?.strokeWidth);
    }

    canvasEraserPen = (ref, eraser, index) => {
        this.props.backCallerChangeEraserMode(index, eraser);
        ref.current?.eraseMode(eraser);
    }

    canvasSavePathsToState = (ref, idx) => {
        ref.current?.exportPaths().then(paths => {
            this.props.backCallerChangeSavePathsToState(paths, idx);
        });
    }

    handleChangeStrokeWidth = (e) => {
        this.props.backCallerChangeStrokeWidth(e);
    }

    handleChangeEraserWidth = (e) => {
        this.props.backCallerChangeEraserWidth(e);
    }

    handleChangeBFSwitchesColor = (e, bIdx, fIdx, c) => {
        this.props.backCallerChangeBFSwitchesColor(e, bIdx, fIdx, c);
    }

    handleChangeBFSwitches = (e) => {
        this.props.backCallerChangeBFSwitches(e);
    }

    handleChangeTextData = (e) => {
        this.props.backCallerChangeTextData(e);
    }

    handleChangeLevelRadio = (e) => {
        // console.log("hello from handleChangeLevelRadio");
        this.props.backCallerChangeLevelRadio(e);
    }

    handleChangeBVisibility = (idx) => {
        this.props.backCallerChangeBVisibility(idx);
    }

    handleChangeDVisibility = (idx) => {
        this.props.backCallerChangeDVisibility(idx);
    }

    handleBFSwitchesColorClass = (val) => {
        switch (val) {
            case 1:
                // return "fw-bold text-danger";
                return "text-danger";
            case 2:
                return "text-warning";
            case 3:
                return "text-success";
            default:
                return "";
        }
    }

    renderStrokes() {
        // console.log();
        this.props.parentState.b.forEach((b, index) => {
            // this.scribbleRefs[index].current?.addPath(b.canvas.paths);
            // console.log(this.scribbleRefs[index]);
            // console.log(b.canvas.paths);
            this.scribbleRefs[index].current?.loadPaths(b.canvas.paths);
        });
    }

    componentDidMount() {
        this.renderStrokes();
        // XXXX
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log("Table shouldComponentUpdate");
    //     return true;
    // }

    componentDidUpdate() {
        // this.renderStrokes();
        // console.log("Table componentDidUpdate");
        // this.props.parentState.b.forEach((b, index) => {
        //     this.scribbleRefs[index].current?.resetCanvas();
        //     this.scribbleRefs[index].current?.loadPaths(b.canvas.paths);
        // });
        // this.scribbleRefs[0].current?.loadPaths(this.props.parentState.b[0].canvas.paths);
        // console.log(this.scribbleRefs[0].current);
        // console.log(this.scribbleRefs[0].current?.setCurrentPaths(this.props.parentState.b[0].canvas.paths));



        // HEAVY XXX

        // for (let i = 0; i < this.scribbleRefs.length; i++) {
        //     this.scribbleRefs[i].current?.exportPaths().then(paths => {
        //         // console.log(this.props.parentState.b[0].canvas.paths);
        //         // console.log(paths);
        //         if (this.props.parentState.b[i].canvas.paths !== paths) {
        //             this.scribbleRefs[i].current?.resetCanvas();
        //             this.scribbleRefs[i].current?.loadPaths(this.props.parentState.b[i].canvas.paths);
        //         }
        //     })
        // }

        // setTimeout(() => {
        //     this.renderStrokes();
        // }, 100);
        // this.scribbleRefs[0].current?.clearCanvas();
    }

    handleCopyMerkmalText = (ubbIdx, mIdx) => {
        console.log(this.props.ubb.beobachtungsfelder[ubbIdx].merkmale[mIdx]);
        const taElement = document.getElementById("textarea_" + ubbIdx);
        const sub = this.props.ubb.beobachtungsfelder[ubbIdx].merkmale_sub.length > 0 ?
            this.props.ubb.beobachtungsfelder[ubbIdx].merkmale_sub + " " : "";

        taElement.value += sub + this.props.ubb.beobachtungsfelder[ubbIdx].merkmale[mIdx] + "\n";
        this.props.backCallerChangeTextData({ target: taElement });
    }

    render() {
        const ubb = this.props.ubb;
        return (
            <>
                <h2>Tabellarische Ansicht</h2>
                <div className="container-fluid p-4 pt-0">
                    <div className="row row-cols-1">
                        {ubb.dimensionen.map((
                            { name, id }, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className={"col border text-start gradient_d" + id}>
                                        <button
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={"#dRow" + index}
                                            aria-expanded="false"
                                            className={"fs-5 fw-bold " + ((id === 1) ? "text-black" : "text-white") + " text-decoration-none"}
                                            aria-controls={"dRow" + index}
                                            onClick={() => this.handleChangeDVisibility(index)}
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                padding: 0,
                                                color: 'inherit',
                                                cursor: 'pointer'
                                              }}
                                        >
                                            Dimension {name}
                                        </button>

                                        {/* <a href="#" data-bs-toggle="collapse" data-bs-target={"#dRow" + index}
                                            aria-expanded="false"
                                            className={"fs-5 fw-bold " + ((id === 1) ? "text-black" : "text-white") + " text-decoration-none"}
                                            aria-controls={"dRow" + index}
                                            onClick={() => this.handleChangeDVisibility(index)}>
                                            Dimension {name}</a> */}
                                    </div>

                                    <div id={"dRow" + index} className={"collapse" + ((this.props.parentState.dVis[index] === 0) ? "" : " show")}>
                                        <div className="row">
                                            <div className="col-4 border text-start fw-bold">Beobachtungsfelder</div>
                                            <div className="col-6 border text-start fw-bold">Beschreibung</div>
                                            <div className="col-2 border fw-bold">Stufe</div>
                                        </div>

                                        {ubb.beobachtungsfelder.map((
                                            { name, d, merkmale, merkmale_sub, color, sub, ...rest }, index) => {
                                            const level = this.props.parentState.b[index].level;
                                            return (
                                                <React.Fragment key={index}>
                                                    {((id - 1) === d) &&
                                                        <>
                                                            <div className="row">

                                                                <div className="col-4 border text-start align-middle d-flex align-items-center">
                                                                    <button className="btn my-1" type="button" style={{ backgroundColor: color }}
                                                                        // data-toggle="collapse" data-target={"#bRow" + index}
                                                                        data-bs-toggle="collapse" data-bs-target={"#bRow" + index}
                                                                        // aria-expanded="false" aria-controls={"bRow" + index}
                                                                        aria-expanded={((this.props.parentState.b[index].visible === 0) ? "false" : "true")}
                                                                        aria-controls={"bRow" + index}
                                                                        onClick={() => this.handleChangeBVisibility(index)}>
                                                                        &nbsp;</button> &nbsp;{name}
                                                                </div>

                                                                <div className="col-6 border text-start align-middle d-flex align-items-center">
                                                                    {sub}
                                                                </div>
                                                                {/* 
                                                                <div className="col-2 border align-middle ubb-step-radio">
                                                                    <ButtonGroup toggle>
                                                                        <ToggleButton
                                                                            type="radio"
                                                                            variant="secondary"
                                                                            name={"options" + index}
                                                                            value="0"
                                                                            checked={level === 0}
                                                                            onClick={this.handleChangeLevelRadio}
                                                                        >
                                                                            0
                                                                        </ToggleButton>
                                                                        <ToggleButton
                                                                            type="radio"
                                                                            variant="secondary"
                                                                            name={"options" + index}
                                                                            value="1"
                                                                            checked={level === 1}
                                                                            onChange={this.handleChangeLevelRadio}
                                                                        >
                                                                            1
                                                                        </ToggleButton>
                                                                    </ButtonGroup>
                                                                </div> */}
                                                                <div className="col-2 border align-middle ubb-step-radio d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                                                    {/* <div className="col-2 border align-middle ubb-step-radio d-flex                         align-items-center justify-content-center"> */}
                                                                    <input type="radio" className="btn-check" name={"options" + index}
                                                                        id={"option_" + index + "_0"} autoComplete="off"
                                                                        checked={this.props.parentState.b[index].level === 0}
                                                                        onChange={this.handleChangeLevelRadio} value="0"
                                                                    />
                                                                    <label style={level === 0 ? { backgroundColor: color } : {}}
                                                                        className="btn" htmlFor={"option_" + index + "_0"}>0</label>

                                                                    <input type="radio" className="btn-check" name={"options" + index}
                                                                        id={"option_" + index + "_1"} autoComplete="off"
                                                                        checked={this.props.parentState.b[index].level === 1}
                                                                        onChange={this.handleChangeLevelRadio} value="1" />
                                                                    <label style={level === 1 ? { backgroundColor: color } : {}}
                                                                        className="btn" htmlFor={"option_" + index + "_1"}>1</label>

                                                                    <input type="radio" className="btn-check" name={"options" + index}
                                                                        id={"option_" + index + "_2"} autoComplete="off"
                                                                        checked={this.props.parentState.b[index].level === 2}
                                                                        onChange={this.handleChangeLevelRadio} value="2" />
                                                                    <label style={level === 2 ? { backgroundColor: color } : {}}
                                                                        className="btn" htmlFor={"option_" + index + "_2"}>2</label>

                                                                    <input type="radio" className="btn-check" name={"options" + index}
                                                                        id={"option_" + index + "_3"} autoComplete="off"
                                                                        checked={this.props.parentState.b[index].level === 3}
                                                                        onChange={this.handleChangeLevelRadio} value="3" />
                                                                    <label style={level === 3 ? { backgroundColor: color } : {}}
                                                                        className="btn" htmlFor={"option_" + index + "_3"}>3</label>

                                                                    <input type="radio" className="btn-check" name={"options" + index}
                                                                        id={"option_" + index + "_4"} autoComplete="off"
                                                                        checked={this.props.parentState.b[index].level === 4}
                                                                        onChange={this.handleChangeLevelRadio} value="4" />
                                                                    <label style={level === 4 ? { backgroundColor: color } : {}}
                                                                        className="btn" htmlFor={"option_" + index + "_4"}>4</label>
                                                                </div>
                                                            </div>

                                                            <div id={"bRow" + index} className={"row collapse" + ((this.props.parentState.b[index].visible === 0) ? "" : " show")}>
                                                                {/* <div id={"bRow" + index} className={"row collapse"}> */}
                                                                <div className="col-4 border text-start">
                                                                    <span className="fw-bold">Merkmale (Liste)</span>
                                                                    {/* {(merkmale_sub !== "") && ({ merkmale_sub })} */}

                                                                    {(merkmale_sub.length > 0) &&
                                                                        <>
                                                                            <br />{merkmale_sub}
                                                                        </>
                                                                    }

                                                                    {/* <ul> */}
                                                                    {Object.values(merkmale).map((
                                                                        { val }, idx) => {
                                                                        return (
                                                                            // <li>
                                                                            <React.Fragment key={idx}>
                                                                                {/* <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox"
                                                                                    value="" id={"bFSwitch_" + index + "_" + idx}
                                                                                    onChange={this.handleChangeBFSwitches}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={"bFSwitch_" + index + "_" + idx}>
                                                                                    {merkmale[idx]}
                                                                                </label>
                                                                            </div> */}


                                                                                <div className="border container text-center" style={{ marginBottom: "4px" }}>
                                                                                    <div className="btn-group border no-print" role="group" aria-label="Ampelbewertung Merkmale">
                                                                                        <button type="button"
                                                                                            className="btn feature-btn"
                                                                                            onClick={(e) => this.handleChangeBFSwitchesColor(e, index, idx, 0)}>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="feature-btn btn btn-danger"
                                                                                            onClick={(e) => this.handleChangeBFSwitchesColor(e, index, idx, 1)}>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn btn-warning feature-btn"
                                                                                            onClick={(e) => this.handleChangeBFSwitchesColor(e, index, idx, 2)}>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn btn-success feature-btn"
                                                                                            onClick={(e) => this.handleChangeBFSwitchesColor(e, index, idx, 3)}>
                                                                                        </button>
                                                                                    </div>

                                                                                    <div className={"col pointerFinger text-start " +
                                                                                        ((this.handleBFSwitchesColorClass(this.props.parentState.b[index].bF[idx])))
                                                                                    } onClick={(e) => this.handleCopyMerkmalText(index, idx)}
                                                                                    >
                                                                                        {merkmale[idx]}
                                                                                    </div>

                                                                                </div>
                                                                                {/* <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox"
                                                                                        id={"bFSwitch_" + index + "_" + idx}
                                                                                        onChange={this.handleChangeBFSwitches}
                                                                                        checked={this.props.parentState.b[index].bF[idx]}
                                                                                    />
                                                                                    <label className={"form-check-label" + ((this.props.parentState.b[index].bF[idx] !== 0) && " fw-bold")} // badge bg-success
                                                                                        htmlFor={"bFSwitch_" + index + "_" + idx}>
                                                                                        {merkmale[idx]}
                                                                                    </label>
                                                                                </div> */}

                                                                            </React.Fragment>
                                                                            // </li>
                                                                        )
                                                                    })}
                                                                    {/* </ul> */}
                                                                </div>

                                                                {/* {(this.props.parentState.pWith === 0 ||
                                                                this.props.parentState.pWith === 1) &&
                                                                <> */}
                                                                <div className="col-8 border text-start">
                                                                    <div className={(this.props.parentState.pWith === 0) ? "collapse" : undefined}>
                                                                        {/* {console.log(this.props.parentState.b[index].canvas.strokeWidth)}
                                                                        {console.log(this.props.parentState.b[index].canvas.paths)} */}
                                                                        <div className="col no-print">
                                                                            <div className="btn-group me-2">
                                                                                <button type="button"
                                                                                    // className={`btn btn-sm btn-outline-dark ${!this.props.parentState.b[index].canvas.eraserMode ? 'border-danger' : ''}`}
                                                                                    className={`btn btn-sm btn-outline-dark`}
                                                                                    style={{
                                                                                        backgroundColor: !this.props.parentState.b[index].canvas.eraserMode ?
                                                                                            ubb.beobachtungsfelder[index].color : '',
                                                                                        color: !this.props.parentState.b[index].canvas.eraserMode ?
                                                                                            "white" : 'black'
                                                                                    }}
                                                                                    onClick={() => this.canvasEraserPen(this.scribbleRefs[index], false, index)}>Stift</button>
                                                                                <button type="button"
                                                                                    className="btn dropdown-toggle dropdown-toggle-split btn-sm btn-outline-dark"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                                                    onClick={() => this.canvasEraserPen(this.scribbleRefs[index], false, index)}
                                                                                >
                                                                                    <span className="visually-hidden">Stift Einstellungen Größe</span>
                                                                                </button>
                                                                                <ul className="dropdown-menu">
                                                                                    <li className="dropdown-item">
                                                                                        <label htmlFor={"canvasStrokeWidth_" + index}
                                                                                            // className="form-label">Stift-Größe ({this.props.parentState.b[index].canvas.strokeWidth} Pixel)</label>
                                                                                            className="form-label">Stift-Größe</label>
                                                                                    </li>
                                                                                    <li className="dropdown-item" style={{ display: 'flex', alignItems: 'center' }}>

                                                                                        <input type="range" className="form-range" min="1" max="25"
                                                                                            step="1" id={"canvasStrokeWidth_" + index}
                                                                                            value={this.props.parentState.b[index].canvas.strokeWidth}
                                                                                            onChange={this.handleChangeStrokeWidth}></input>
                                                                                        <svg width="100" height="60">
                                                                                            <circle
                                                                                                cx="30"
                                                                                                cy="30"
                                                                                                r={this.props.parentState.b[index].canvas.strokeWidth / 2}
                                                                                                fill={color}
                                                                                            // stroke="black"
                                                                                            // strokeWidth="1"
                                                                                            // stroke-dasharray="3,3"
                                                                                            />
                                                                                        </svg>

                                                                                    </li>

                                                                                </ul>
                                                                            </div>

                                                                            <div className="btn-group me-2">
                                                                                <button type="button"
                                                                                    className={`btn btn-sm btn-outline-dark`}
                                                                                    // className={`btn btn-sm btn-outline-dark ${this.props.parentState.b[index].canvas.eraserMode ? 'border-danger' : ''}`}
                                                                                    style={{
                                                                                        // borderColor: this.props.parentState.b[index].canvas.eraserMode ?
                                                                                        //     ubb.beobachtungsfelder[index].color : '',
                                                                                        color: this.props.parentState.b[index].canvas.eraserMode ?
                                                                                            ubb.beobachtungsfelder[index].color : '',

                                                                                    }}
                                                                                    onClick={() => this.canvasEraserPen(this.scribbleRefs[index], true, index)}>Radierer</button>
                                                                                <button type="button"
                                                                                    className="btn dropdown-toggle dropdown-toggle-split btn-sm btn-outline-dark"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                                                    onClick={() => this.canvasEraserPen(this.scribbleRefs[index], true, index)}
                                                                                >
                                                                                    <span className="visually-hidden">Radierer Einstellungen Größe</span>
                                                                                </button>
                                                                                <ul className="dropdown-menu">
                                                                                    <li className="dropdown-item">
                                                                                        <label htmlFor={"canvasEraserWidth_" + index}
                                                                                            // className="form-label">Radierer-Größe ({this.props.parentState.b[index].canvas.eraserWidth} Pixel)</label>
                                                                                            className="form-label">Radierer-Größe</label>
                                                                                    </li>
                                                                                    <li className="dropdown-item" style={{ display: 'flex', alignItems: 'center' }}>

                                                                                        <input type="range" className="form-range" min="1" max="50"
                                                                                            step="1" id={"canvasEraserWidth_" + index}
                                                                                            value={this.props.parentState.b[index].canvas.eraserWidth}
                                                                                            onChange={this.handleChangeEraserWidth}></input>
                                                                                        <svg width="100" height="60">
                                                                                            <circle
                                                                                                cx="30"
                                                                                                cy="30"
                                                                                                r={this.props.parentState.b[index].canvas.eraserWidth / 2}
                                                                                                fill="transparent"
                                                                                                stroke="black"
                                                                                                strokeWidth="2"
                                                                                                strokeDasharray="2"
                                                                                            />
                                                                                        </svg>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                            {/* <button type="button" className="btn border" onClick={() => this.canvasEraserPen(this.scribbleRefs[index], false)}>
                                                                            Pen
                                                                        </button> */}


                                                                            {/* 
                                                                        <div className="btn-group">
                                                                            <button type="button" className="btn border" onClick={() => this.canvasEraserPen(this.scribbleRefs[index], true)}>Radierer</button>
                                                                            <button type="button" className="btn dropdown-toggle dropdown-toggle-split border" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                <span className="visually-hidden">Radierer Einstellungen Dropdown</span>
                                                                            </button>
                                                                            <ul className="dropdown-menu">
                                                                                <li><a className="dropdown-item" href="#">Größe 1</a></li>
                                                                                <li><a className="dropdown-item" href="#">Größe 5</a></li>
                                                                                <li><a className="dropdown-item" href="#">Größe 10</a></li>
                                                                                <li><hr className="dropdown-divider" /></li>
                                                                            </ul>
                                                                        </div> */}

                                                                            {/* <button type="button" className="btn border" onClick={() => this.canvasEraserPen(this.scribbleRefs[index], true)}>
                                                                            Eraser
                                                                        </button> */}
                                                                            <button type="button" className="btn btn-outline-dark btn-sm me-2"
                                                                                onClick={() => this.canvasUndoRedo(this.scribbleRefs[index], true)}>
                                                                                Undo
                                                                            </button>

                                                                            <button type="button" className="btn btn-outline-dark btn-sm me-2"
                                                                                onClick={() => this.canvasUndoRedo(this.scribbleRefs[index], false)}>
                                                                                Redo
                                                                            </button>

                                                                            <button type="button" className="btn btn-outline-dark btn-sm"
                                                                                onClick={() => this.canvasClear(this.scribbleRefs[index], index)}>
                                                                                Stiftfeld leeren
                                                                            </button>


                                                                        </div>
                                                                        {/* {console.log(index, this.props.parentState.b[index].canvas.paths)}
                                                                        {console.log(this.scribbleRefs[index])} */}
                                                                        <div key={"scribble_" + index} className="row p-0 m-0">
                                                                            <ReactSketchCanvas
                                                                                style={{
                                                                                    padding: "0px",
                                                                                    margin: "0px",
                                                                                }}
                                                                                id={"scribble_" + index}
                                                                                ref={this.scribbleRefs[index]}
                                                                                width="100%"
                                                                                height="500px"
                                                                                // paths={this.props.parentState.b[index].canvas.paths}
                                                                                strokeWidth={this.props.parentState.b[index].canvas.strokeWidth}
                                                                                eraserWidth={this.props.parentState.b[index].canvas.eraserWidth}
                                                                                strokeColor={color}
                                                                                onChange={() => { this.canvasSavePathsToState(this.scribbleRefs[index], index) }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div id={"divTextarea_" + index} className={"ta-container row" + ((this.props.parentState.pWith === 2) && " collapse")}>
                                                                        <textarea className="form-control h-100 d-inline-block mb-3 ubbTa"
                                                                            id={"textarea_" + index} autoComplete="off"
                                                                            name={"textarea_" + index}
                                                                            value={this.props.parentState.b[index].text}
                                                                            onChange={this.handleChangeTextData}
                                                                            placeholder="Anmerkungen"
                                                                            rows="10" />
                                                                    </div>

                                                                </div>
                                                                {/* </> */}
                                                                {/* } */}
                                                                {/* {(this.props.parentState.pWith === 1) &&
                                                                <>
                                                                    <div className="col-4 border text-start">
                                                                        <textarea className="form-control h-100 d-inline-block"
                                                                            id={"textarea_" + index} autoComplete="off"
                                                                            name={"textarea_" + index}
                                                                            value={this.props.parentState.b[index].text}
                                                                            onChange={this.handleChangeTextData}
                                                                            placeholder="Anmerkungen" />
                                                                    </div>
                                                                    <div className="col-4 border text-start">
                                                                        Scribble Dibble
                                                                    </div>
                                                                </>
                                                            } */}
                                                                {/* {(this.props.parentState.pWith === 1 ||
                                                                this.props.parentState.pWith === 2) &&
                                                                <>
                                                                    <div className="col-8 border text-start">
                                                                        <textarea className="form-control h-100 d-inline-block"
                                                                            id={"textarea_" + index} autoComplete="off"
                                                                            name={"textarea_" + index}
                                                                            value={this.props.parentState.b[index].text}
                                                                            onChange={this.handleChangeTextData}
                                                                            placeholder="Anmerkungen" />
                                                                    </div>
                                                                </>
                                                            } */}
                                                            </div>
                                                        </>}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }
}