import React, { Component, createRef } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';

class HiddenCanvases extends Component {
    constructor(props) {
        super(props);
        this.scribbleRefs = [...Array(props.parentState.b.length)].map(() => createRef());
    }

    componentDidMount() {
        this.loadAllPaths();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.parentState !== this.props.parentState) {
            this.loadAllPaths();
        }
    }

    loadAllPaths = () => {
        const { parentState } = this.props;
        const promises = parentState.b.map((item, index) => {
            const canvasRef = this.scribbleRefs[index].current;
            if (canvasRef && item.canvas.paths && item.canvas.paths.length > 0) {
                // console.log(`Loading paths for canvas ${index}:`, item.canvas.paths);
                return canvasRef.loadPaths(item.canvas.paths);
            } else {
                // console.warn(`No paths to load or canvas ref is null for index ${index}.`);
                return Promise.resolve();
            }
        });

        Promise.all(promises)
            .then(() => {
                this.generateImages();
            })
            .catch(error => console.error('Error loading paths:', error));
    }

    generateImages = async () => {
        const { onImagesGenerated } = this.props;
        const images = [];
        for (let i = 0; i < this.scribbleRefs.length; i++) {
            const canvasRef = this.scribbleRefs[i].current;
            if (canvasRef) {
                try {
                    const paths = await canvasRef.exportPaths();
                    if (paths && paths.length > 0) {
                        const dataUrl = await canvasRef.exportImage('png');
                        images.push(dataUrl);
                        // console.log(`Image data generated for canvas ${i}`);
                    } else {
                        // console.log(`No strokes found for canvas ${i}`);
                        images.push(null);
                    }
                } catch (error) {
                    console.error(`Error exporting canvas ${i} to image:`, error);
                    images.push(null);
                }
            } else {
                console.warn(`Canvas ref at index ${i} is null.`);
                images.push(null);
            }
        }
        onImagesGenerated(images); // Callback to handle the generated images
    }

    render() {
        const { parentState } = this.props;
        return (
            <div style={{ display: 'block' }}>
                {parentState.b.map((item, index) => (
                    <ReactSketchCanvas
                        key={index}
                        ref={this.scribbleRefs[index]}
                        style={{ padding: '0px', margin: '0px' }}
                        width="1280px"
                        height="510px"
                        strokeWidth={item.canvas.strokeWidth}
                        eraserWidth={item.canvas.eraserWidth}
                        strokeColor={item.canvas.strokeColor}
                    />
                ))}
            </div>
        );
    }
}

export default HiddenCanvases;
