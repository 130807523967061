export const ubb = {
    dimensionen: [
        { id: 1, name: 'Schülerhandeln' },
        { id: 2, name: 'Lehrerhandeln' },
        { id: 3, name: 'Lernangebot' },
    ],
    beobachtungsfelder: [
        {
            id: 1, d: 0, name: 'Engagement und Beteiligung',
            donutText: ['Engagement', 'und Beteiligung'],
            // color: "#f6883e",
            color: "rgb(238,139,49)",
            sub: "Die Lernenenden beteiligen sich engagiert am Unterrichtsgeschehen.",
            merkmale: [
                "arbeiten aktiv im Unterricht mit.",
                "bringen eigene Überlegungen, Erfahrungen und Kompetenzen ein.",
                "übernehmen und bearbeiten Arbeitsaufträge zügig.",
                "suchen bei Verständnisschwierigkeiten Unterstützung bei anderen Lernenden oder bei der Lehrkraft.",
                "setzen sich mit den Themen und Problemstellungen kritisch auseinander."
            ],
            merkmale_sub: "Die Lernenenden..."
        },
        {
            id: 2, d: 0, name: 'Klassenklima',
            donutText: ['Klassenklima'],
            // color: "#ffb633",
            color: "rgb(251,184,2)",
            sub: "Die Lernenenden begegnen einander und der Lehrkraft mit Wertschätzung und Respekt.",
            merkmale: [
                "halten sich an vereinbarte Regeln und Rituale.",
                "sprechen in einem respektvollen und höflichen Ton mit der Lehrkraft und miteinander.",
                "lassen andere ausreden und unterschiedliche Meinungen gelten.",
                "nehmen aufeinander Rücksicht und stellen andere nicht bloß.",
                "kooperieren aufgabenbezogen und arbeiten konstruktiv zusammen."
            ],
            merkmale_sub: "Die Lernenenden..."
        },
        {
            id: 3, d: 0, name: 'Kompetenzentwicklung',
            donutText: ['Kompetenz-', 'entwicklung'],
            // color: "#60a53e",
            color: "rgb(98,166,47)",
            sub: "Die Lernenden zeigen bei der Bearbeitung der Aufgaben einen Lernfortschritt und entwickeln Kompetenzen weiter.",
            merkmale: [
                "bewältigen Arbeitsaufträge aus unterschiedlichen Anforderungsbereichen.",
                "wenden fachspezifische und überfachliche Methoden an.",
                "stellen ihre Lösungen nachvollziehbar dar.",
                "setzen sich inhaltlich mit den Ergebnissen und Beiträgen anderer auseinander.",
                "reflektieren ihren Lernprozess und ihren Lernfortschritt."
            ],
            merkmale_sub: "Die Lernenenden..."
        },
        {
            id: 4, d: 1, name: 'Konstruktive Unterstützung',
            donutText: ['Konstruktive', 'Unterstützung'],
            // color: "#00868d",
            color: "rgb(0,134,143)",
            sub: "Die Lehrkraft unterstützt die Lernenden in ihrem Lernprozess.",
            merkmale: [
                "fordert die Mitwirkung und Eigenverantwortung aller Lernenden ein.",
                "gibt adaptive Hilfestellungen, damit die Lernenden die Lernziele erreichen können.",
                "unterstützt die Lernenden durch lernwirksames Feedback im Unterricht.",
                "pflegt einen konstruktiven Umgang mit Fehlern und nutzt sie als Chancen im Lernprozess.",
                "begleitet den Lernfortschritt durch formatives Assessment."
            ],
            merkmale_sub: "Die Lehrkraft..."
        },
        {
            id: 5, d: 1, name: 'Strukturierte Klassenführung',
            donutText: ['Strukturierte', 'Klassenführung'],
            // color: "#00589b",
            color: "rgb(0,87,158)",
            sub: "Die Lehrkraft führt die Klasse pädagogisch adäquat.",
            merkmale: [
                "pflegt eine freundliche und respektvolle Beziehung zur Klasse.",
                "hat alle Lernenden im Blick.",
                "sorgt für die Einhaltung vereinbarter Regeln und Rituale.",
                "reagiert frühzeitig und angemessen auf Störungen.",
                "nutzt die zur Verfügung stehende Unterrichtszeit effizient und bespricht organisatorische und pädagogische Fragen in geeignetem Rahmen."
            ],
            merkmale_sub: "Die Lehrkraft..."
        },
        {
            id: 6, d: 1, name: 'Ziel- und Kompetenzorientierung',
            donutText: ['Ziel- und Kompetenz-', 'orientierung'],
            // color: "#555495",
            color: "rgb(90,83,152)",
            sub: "Die Lehrkraft fokussiert die angestrebten Lernziele und Kompetenzen.",
            merkmale: [
                "baut die Unterrichtsschritte stimmig aufeinander auf, so dass sich eine klare Struktur ergibt.",
                "macht transparent, was die Lernenden leisten und erarbeiten sollen.",
                "achtet darauf, dass die Ergebnisse auf die Lernziele abgestimmt und lernwirksam gesichert werden.",
                "integriert Konsolidierung und intelligentes Üben.",
                "initiiert die Reflexion der Lernergebnisse im Hinblick auf die angestrebten Lernziele und Kompetenzen."
            ],
            merkmale_sub: "Die Lehrkraft..."
        },
        {
            id: 7, d: 2, name: 'Ziele und Inhalte',
            donutText: ['Ziele und', 'Inhalte'],
            // color: "#9f3376",
            color: "rgb(156,51,121)",
            sub: "Die Lernziele sind angemessen und die ausgewählten Inhalte werden fachlich korrekt vermittelt.",
            merkmale: [
                "Die Ziele und Inhalte sind dazu geeignet, fachliche und überfachliche Kompetenzen zu fördern.",
                "Die Inhalte werden mit geeigneter Schwerpunktsetzung bildungsplankonform ausgewählt und didaktisch reduziert.",
                "Die Inhalte sind für die Lernenden bedeutsam und nehmen Bezug auf ihre Lebens- bzw. Berufswelt.",
                "Die Inhalte werden fachlich richtig dargestellt und in korrekter Fachund Zielsprache vermittelt.",
                "Die Aufgaben- und Problemstellung ermöglichen eine vollständige bzw. Teile einer vollständigen Lernhandlung."
            ],
            merkmale_sub: ""
        },
        {
            id: 8, d: 2, name: 'Differenzierung',
            donutText: ['Differenzierung'],
            // color: "#dd003c",
            color: "rgb(209,15,54)",
            sub: "Das Lernangebot ist differenziert und berücksichtigt die Heterogenität der Lerngruppe.",
            merkmale: [
                "Das Lernangebot knüpft an das Vorwissen der Lernenden an.",
                "Das Lernangebot berücksichtigt das individuelle Lern- und Arbeitsverhalten.",
                "Möglichkeiten der Binnendifferenzierung werden didaktisch reflektiert angeboten.",
                "Das Lernangebot ist sprachsensibel gestaltet und beinhaltet Hilfen bei sprachlichen Barrieren.",
                "Analoge bzw. digitale Lernhilfen und Übungsmöglichkeiten stehenzur Verfügung, die individuell genutzt werden können."
            ],
            merkmale_sub: ""
        },
        {
            id: 9, d: 2, name: 'Kognitive Aktivierung',
            donutText: ['Kognitive', 'Aktivierung'],
            // color: "#ec4278",
            color: "rgb(229,68,121)",
            sub: "Das Lernangebot fordert die Lernenden kognitiv heraus.",
            merkmale: [
                "Die Lernsituation ist problemorientiert und angemessen komplex.",
                "Die Lernsituation ermutigt dazu, eigene Ideen, Konzepte und Lösungswege zu erläutern.",
                "Die Aufgaben, Fragen und Impulse fördern das Verstehen und vertiefte Nachdenken der Lernenden.",
                "Durch das methodische Arrangement können sich die Lernenden produktiv mit den Aufgaben auseinandersetzen.",
                "Funktional eingesetzte Medien unterstützen den Lernprozess."
            ],
            merkmale_sub: ""
        },
    ],
    stufen: [
        { id: 0, text: 'wurde bei der Beratung bzw. Beurteilung nicht berücksichtigt' },
        { id: 1, text: 'erhebliche Defizite, Entwicklung dringend notwendig' },
        { id: 2, text: 'erkennbare Defizite, Entwicklungsbedarf' },
        { id: 3, text: 'gute Grundlage, noch ausbaufähig' },
        { id: 4, text: 'gelingt schon gut oder sehr gut' },
    ],
}

export const default_toast_showtime = 7500;

export const json_export_file_type = "ubb_state_export";