import { Modal, Button } from 'react-bootstrap';
import './Imprint.css';
import { linkTo_UnCryptMailto } from '../../Functions';

const Imprint = ({ parentState, backCallerHandleCancelModalAreYouSure }) => {
    return (
        <>

            <div className="container-fluid text-start">
                <div className="row mb-3">
                    <div className="col mt-2">
                        <h2>Herausgeber</h2>
                        <p>
                            Land Baden-Württemberg,<br />
                            vertreten durch das<br />
                            Zentrum für Schulqualität und Lehrerbildung (ZSL)
                        </p>
                        <img
                            id="ZSLLogo" src={`${process.env.PUBLIC_URL}/ZSL-Logo-klein.png`}
                            alt="Zentrum für Schulqualität und Lehrerbildung" title="Zentrum für Schulqualität und Lehrerbildung"
                            className="mb-4 mt-2"
                        />
                        <p>Heilbronner Str. 314<br />
                            70469 Stuttgart<br />
                            Tel.: +49 711 21859-401<br />
                            E-Mail: <a href={linkTo_UnCryptMailto('nbjmup;qptutufmmfA{tm/lw/cxm/ef')}>poststelle [at] zsl [dot] kv [dot] bwl [dot] de</a></p>
                        <p>Vertretungsberechtigte Person: Herr Präsident Prof. Dr. Thomas Riecke-Baulecke</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Verantwortlich im Sinne des Presserechts</h5>
                        <p>
                            Torben Würth<br />
                            Zentrum für Schulqualität und Lehrerbildung (ZSL)<br />
                            Heilbronner Str. 314<br />
                            70469 Stuttgart<br />
                            Tel.: +49 711 21859-401<br />
                            <a href={linkTo_UnCryptMailto('nbjmup;Upscfo/XvfsuiA{tm/lw/cxm/ef')}>Torben [dot] Wuerth [at] zsl [dot] kv [dot] bwl [dot] de</a><br />
                        </p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Kontaktdaten des behördlichen Datenschutzbeauftragten</h5>
                        <p><a href={linkTo_UnCryptMailto('nbjmup;ebufotdivu{A{tm/lw/cxm/ef')}>datenschutz [at] zsl [dot] kv [dot] bwl [dot] de</a></p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz</h5>
                        <p>DE277605267</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h3>Haftungsausschluss</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h5>Haftung für Inhalte</h5>
                        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                            Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
                            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                            nach den allgemeinen Gesetzen verantwortlich. Nach §§ 7 bis 10 TMG sind wir als
                            Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                            Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                            Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                            Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
                            Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                            möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h5>Haftung für Links</h5>
                        <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                            Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                            inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                            derartige Links umgehend entfernen.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h5>Urheberrecht</h5>
                        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
                            dem deutschen Urheberrecht. Die Seiten dürfen zum privaten Gebrauch vervielfältigt und,
                            soweit keine Rechte Dritter betroffen sind, unter Quellenangabe zur Veranschaulichung
                            und zum Gebrauch an nichtgewerblichen Bildungseinrichtungen öffentlich zugänglich gemacht
                            werden, soweit dies zu dem jeweiligen Zweck geboten und zur Verfolgung nicht
                            kommerzieller Zwecke gerechtfertigt ist. Online-Einspeisungen, Änderungen oder
                            eine darüber hinaus gehende, insbesondere eine kommerzielle Nutzung sind nur mit
                            vorheriger schriftlicher Zustimmung zulässig. Außerhalb des Satzes 2 dieses Absatzes
                            gilt: Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                            außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                            jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für
                            den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
                            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                            Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
                            auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                            Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                            umgehend entfernen.</p>
                            <p>Inhalte, die abweichend hiervon anderen Lizenzen unterliegen (z. B. Creative Commons), sind als solche gekennzeichnet.</p>
                    </div>
                </div>
            </div>

            {/* <div className="container-fluid text-start mt-0">
                <div className="row">

                    <div className="col-12">
                        <p><strong>Angaben gemäß § 5 TMG</strong></p>
                        <p>Ministerium für Kultus, Jugend und Sport Baden-Württemberg<br />
                            Postfach 10 34 42<br />
                            70029 Stuttgart</p>

                        <p><strong>Vertreten durch:</strong></p>
                        <p>Ministerin für Kultus, Jugend und Sport<br />
                            Theresa Schopper</p>

                        <p><strong>Kontakt:</strong></p>
                        <p>Telefon: +49 (0)711 279-0<br />
                            Telefax: +49 (0)711 279-4000<br />
                            E-Mail: <a href="mailto:poststelle@km.kv.bwl.de">poststelle@km.kv.bwl.de</a></p>

                        <p><strong>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</strong></p>
                        <p>DE123456789</p>

                        <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong></p>
                        <p>[Name des Verantwortlichen]<br />
                            Ministerium für Kultus, Jugend und Sport Baden-Württemberg<br />
                            Postfach 10 34 42<br />
                            70029 Stuttgart</p>

                        <h2>Haftungsausschluss:</h2>

                        <h3>Haftung für Inhalte</h3>
                        <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                        <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

                        <h3>Haftung für Links</h3>
                        <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
                        <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

                        <h3>Urheberrecht</h3>
                        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
                        <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Imprint;