import { ToastContainer, Toast } from 'react-bootstrap';
import './Toasts.css';

const Toasts = ({ parentState, backCallerHandleToggleToast }) => {
  return (
    <div className="no-print">
      {(Object.keys(parentState.helper.toasts).length > 0) && (
        <ToastContainer style={{ paddingRight: "10px", paddingBottom: "10px" }}
          position="bottom-end" className="position-fixed"
          id="tc_1">
          {parentState.helper.toasts.map((toast, idx) => {
            const textColorClass = toast.severity !== 'danger' ? 'text-black' : 'text-white';
            return (
              <Toast bg={toast.severity} key={idx}
                show={toast.visible}
                onClose={(e) => backCallerHandleToggleToast(toast.id)} delay={toast.showtime} autohide>
                <Toast.Header className="text-start">
                  <strong className="me-auto">{toast.header}</strong>
                  <small>{toast.timestamp}</small>
                </Toast.Header>
                <Toast.Body className={`${textColorClass} text-start`}>{toast.body}</Toast.Body>
              </Toast>
            )
          })}
        </ToastContainer>
      )}
    </div>
  );
}

export default Toasts;