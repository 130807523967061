import React from 'react';
import { PureComponent } from "react";
import * as d3 from "d3";
// import { Popover } from 'react-bootstrap';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { ubb, default_toast_showtime } from "../Consts.js"

import './Donut.css';

const dp_size = 30;
const arc_thickness = 70;
const maxWidth = 800;
const maxHeight = 800;

export class Donut extends PureComponent {

    // printSVG = () => {
    //     const svgElement = document.getElementById('ubbchart').innerHTML;
    //     const printWindow = window.open('', '_blank');

    //     const style = `
    //         body {
    //             font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //             'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //             sans-serif;
    //         }
    //     `;

    //     printWindow.document.open();
    //     printWindow.document.write(`
    //         <html>
    //             <head>
    //                 <style>${style}</style>
    //             </head>
    //             <body>
    //                 <div>${svgElement}</div>
    //             </body>
    //         </html>
    //     `);
    //     printWindow.document.close();
    //     printWindow.print();
    //     printWindow.close();
    // };

    // exportSVG = () => {
    //     const svgElement = document.getElementById('ubbchart').querySelector('svg');
    //     const svgData = new XMLSerializer().serializeToString(svgElement);
    //     const canvas = document.createElement('canvas');
    //     const ctx = canvas.getContext('2d');
    //     const img = new Image();

    //     img.onload = () => {
    //         canvas.width = img.width;
    //         canvas.height = img.height;
    //         ctx.drawImage(img, 0, 0);
    //         const imgURL = canvas.toDataURL('image/png');
    //         const dlLink = document.createElement('a');
    //         dlLink.download = 'graph.png';
    //         dlLink.href = imgURL;
    //         dlLink.dataset.downloadurl = ['image/png', dlLink.download, dlLink.href].join(':');
    //         document.body.appendChild(dlLink);
    //         dlLink.click();
    //         document.body.removeChild(dlLink);
    //     };

    //     const style = `
    //         body {
    //             font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //             'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //             sans-serif;
    //         }
    //     `;

    //     const svgBlob = new Blob([`<style></style>${svgData}`], { type: 'image/svg+xml;charset=utf-8' });
    //     const url = URL.createObjectURL(svgBlob);
    //     img.src = url;
    // }

    // handlePopovers = (event, donutElem) => {
    //     let existingPopover = bootstrap.Popover.getInstance(this);
    //     [].slice.call(document.querySelectorAll('.donutArcs')).forEach(function (popover) {
    //         let bootstrapPopover = bootstrap.Popover.getInstance(popover);
    //         if (bootstrapPopover !== null &&
    //             bootstrapPopover !== undefined) {
    //             bootstrapPopover.hide();
    //         }
    //     });

    //     let idx = event.srcElement.__data__.index;
    //     let marked = this.props.parentState.b[idx].bF;
    //     let bF = ubb.beobachtungsfelder[idx];
    //     let popoverContent = [];

    //     let cls = "text-center bold fs-5 popover-padding";
    //     cls += ` popTitle${idx}`;

    //     popoverContent.push(`<div class="${cls}">${ubb.dimensionen[bF.d].name} <br> ${bF.name}</div>`)

    //     popoverContent.push(bF.sub);
    //     popoverContent.push(bF.merkmale_sub);
    //     Object.values(bF.merkmale).map((merkmal, index) => {
    //         if (marked[index] === 1) {
    //             popoverContent.push(`<span class="fs-6 text-danger">${merkmal}</span>`);
    //         }
    //         else if (marked[index] === 2) {
    //             popoverContent.push(`<span class="fs-6 text-warning">${merkmal}</span>`);
    //         }
    //         else if (marked[index] === 3) {
    //             popoverContent.push(`<span class="fs-6 text-success">${merkmal}</span>`);
    //         }
    //         else {
    //             popoverContent.push(merkmal);
    //         }
    //     });

    //     let popoverContentString = popoverContent[2].length > 0 ? popoverContent[2] + "<ul>" : "<ul>";
    //     for (let i = 3; i < popoverContent.length; i++) {
    //         popoverContentString += "<li>" + popoverContent[i] + "</li>";
    //     }
    //     popoverContentString += "</ul>";

    //     let popover = new bootstrap.Popover(donutElem, {
    //         title: popoverContent[0],
    //         content: popoverContentString,
    //         html: true,
    //         trigger: 'manual',
    //         animation: true
    //     });
    //     popover.show();
    // }

    handlePopoversWithRef = (event, donutElem) => {
        let idx = event.srcElement.__data__.index;
        let marked = this.props.parentState.b[idx].bF;
        let bF = ubb.beobachtungsfelder[idx];
        let popoverContent = [];
        let cls = "text-center bold fs-5 popover-padding";
        cls += ` popTitle${idx}`;

        popoverContent.push(`<div class="${cls}">${ubb.dimensionen[bF.d].name} <br> ${bF.name}</div>`)
        popoverContent.push(bF.sub);
        popoverContent.push(bF.merkmale_sub);
        Object.values(bF.merkmale).map((merkmal, index) => {
            if (marked[index] === 1) {
                popoverContent.push(`<span class="fs-6 text-danger">${merkmal}</span>`);
            }
            else if (marked[index] === 2) {
                popoverContent.push(`<span class="fs-6 text-warning">${merkmal}</span>`);
            }
            else if (marked[index] === 3) {
                popoverContent.push(`<span class="fs-6 text-success">${merkmal}</span>`);
            }
            else {
                popoverContent.push(merkmal);
            }
        });

        let popoverContentString = popoverContent[2].length > 0 ? popoverContent[2] + "<ul>" : "<ul>";
        for (let i = 3; i < popoverContent.length; i++) {
            popoverContentString += "<li>" + popoverContent[i] + "</li>";
        }
        popoverContentString += "</ul>";

        if (this.popoverInstance) {
            this.popoverInstance.dispose();
        }

        this.popoverInstance = new bootstrap.Popover(donutElem, {
            title: popoverContent[0],
            content: popoverContentString,
            html: true,
            trigger: 'manual',
            animation: true,
        });

        this.popoverInstance.show();
        event.stopPropagation();
    }

    drawDonut() {
        // console.log("DRAW DONUT!!!");
        // console.log("d3", d3)
        const ubb = this.props.ubb;
        const component = this;

        const donutData = ubb.beobachtungsfelder.map((feld) => ({
            name: feld.name,
            value: dp_size,
            id: feld.id
        }));

        var screenWidth = window.innerWidth;
        var screenHeight = window.innerHeight;
        const workerSize = Math.min(screenWidth, screenHeight);

        var margin = { left: 20, top: 20, right: 20, bottom: 20 },
            width = Math.min(workerSize, maxWidth) - margin.left - margin.right,
            height = Math.min(workerSize, maxHeight) - margin.top - margin.bottom;

        var radius = width * 0.5;
        var radius_0 = 0.15 * radius;
        var radius_1 = 0.2 * radius;
        var radius_2 = 0.6 * radius;
        var radius_3 = 0.7 * radius;
        var radius_4 = 0.9 * radius;

        var innerRadius = (width * 0.75 / 10);

        const existingSvg = d3.select("#ubbchart").select("svg");
        if (!existingSvg.empty()) {
            existingSvg.remove();
        }

        // console.log("s,mdnf", d3.select("#ubbchart"))

        var svg = d3.select("#ubbchart").append("svg")
            .attr("width", (width + margin.left + margin.right))
            .attr("height", (height + margin.top + margin.bottom))
            .style("background-color", "#97c4df")
            .style("font-family", "Arial, Helvetica, sans-serif")
            .append("g").attr("class", "wrapper")
            .attr("transform", "translate(" + (width / 2 + margin.left) + "," + (height / 2 + margin.top) + ")");

        // Define the gradient
        var gradient = svg.append("svg:defs")
            .append("svg:radialGradient")
            .attr("id", "gradient")
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "100%")
            .attr("y2", "100%")
            .attr("spreadMethod", "pad");

        // Define the gradient colors
        gradient.append("svg:stop")
            .attr("offset", "0%")
            // .attr("stop-color", "#f25221")
            .attr("stop-color", "rgb(234,89,6)")
            .attr("stop-opacity", 1);

        gradient.append("svg:stop")
            .attr("offset", "100%")
            // .attr("stop-color", "#de003b")
            .attr("stop-color", "rgb(213,8,60")
            .attr("stop-opacity", 1);

        // circles
        svg.append('circle')
            .attr('r', radius_4)
            // .attr('fill', '#a5a5a5')
            .attr('fill', 'rgb(200,200,200)')
        // .attr('stroke', '#ffffff')
        // .attr('stroke-width', '0.3em');
        svg.append('circle')
            .attr('r', radius_2)
            // .attr('fill', '#000000')
            .attr('fill', '#d9d9d9')
        // .attr('stroke', '#000000')
        // .attr('stroke-width', '1.2em');
        // inner red circle w/ gradient
        svg.append('circle')
            .attr('r', radius_1)
            // .attr('stroke', '#ffffff')
            // .attr('stroke-width', '0.2em')
            .attr('fill', 'url(#gradient)')


        // inner paths and text
        // var worker = "m-" + innerRadius + ",+" + "10" + " a 3 3 0 0 1 " + 2 * innerRadius + " 0";
        var worker = "m-" + radius_0 + ",+" + "10" + " a 3 3 0 0 1 " + 2 * radius_0 + " 0";
        svg.append("path")
            .attr("id", "pLernprozess")
            .attr("d", worker)
            .style("fill", "none")

        worker = "m-" + (radius_3 * 0.93) + ",-" + "0" + " a 3 3 0 0 1 " + 2 * (radius_3 * 0.93) + " 0";
        // console.log(worker)
        svg.append("path")
            .attr("id", "pLernangebot")
            .attr("d", worker)
            .style("fill", "none")

        worker = "m-" + radius_3 + ",-" + "0" + " a 3 3 0 0 0 " + 2 * radius_3 + " -0";
        svg.append("path")
            .attr("id", "pLehrkraeftehandeln")
            .attr("d", worker)
            .style("fill", "none")

        worker = "m-" + radius_3 + ",-" + "0" + " a 3 3 0 0 0 " + 2 * radius_3 + " 0";
        svg.append("path")
            .attr("id", "pSchuelerhandlen")
            .attr("d", worker)
            .style("fill", "none")

        svg.append("text")
            .style("text-anchor", "middle")
            .append("textPath")
            .attr("xlink:href", "#pLernprozess")
            .attr("startOffset", "50%")
            .text("LERNPROZESS")
            .style("fill", "#ffffff")
            .attr('font-size', '1em');

        let greyCircleFontSize = "1.3em";
        let outerCircleFontSize = "1.3em";
        // XXX CHANGE this on resolution / size available XXX

        svg.append("text")
            .style("text-anchor", "middle")
            .append("textPath")
            .attr("xlink:href", "#pLernangebot")
            .attr("startOffset", "50%")
            .text("LERNANGEBOT")
            .style("fill", "#000000")
            .attr('font-size', greyCircleFontSize);

        svg.append("text")
            .style("text-anchor", "middle")
            .append("textPath")
            .attr("xlink:href", "#pLehrkraeftehandeln")
            .attr("startOffset", "16.5%")
            .text("LEHRERHANDLEN")
            .style("fill", "#000000")
            .attr('font-size', greyCircleFontSize);

        svg.append("text")
            .style("text-anchor", "middle")
            .append("textPath")
            .attr("xlink:href", "#pSchuelerhandlen")
            .attr("startOffset", "83.5%")
            .text("SCHÜLERHANDLEN")
            .style("fill", "#000000")
            .attr('font-size', greyCircleFontSize);

        var arc = d3.arc()
            .innerRadius(width * 0.75 / 2)
            .outerRadius(width * 0.75 / 2 + arc_thickness);

        let sp = 60;
        var pie = d3.pie()
            // .startAngle(60 * Math.PI / 180)
            // .endAngle(60 * Math.PI / 180 + 2 * Math.PI)
            .startAngle(sp * Math.PI / 180)
            .endAngle(sp * Math.PI / 180 + 2 * Math.PI)
            .value(function (d) { return d.value; })
            .padAngle(.0)
            .sort(null);

        //Create the donut slices and also the invisible arcs for the text 
        svg.selectAll(".donutArcs")
            .data(pie(donutData))
            .enter().append("path")
            .attr("class", "donutArcs")
            .style("cursor", "pointer")
            // .style("z-index", "10000") // XXX
            .attr("d", arc)
            .style("fill", function (d, i) {
                return ubb.beobachtungsfelder[i].color;
            })
            .each(function (d, i) {
                //Search pattern for everything between the start and the first capital L
                var firstArcSection = /(^.+?)L/;

                // console.log("D >>" + d);

                //Grab everything up to the first Line statement
                var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                //Replace all the comma's so that IE can handle it
                newArc = newArc.replace(/,/g, " ");

                //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2) 
                //flip the end and start position
                // if (d.endAngle > 90 * Math.PI / 180) {
                if (d.data.id > 1 && d.data.id < 6) {
                    //     d.data.id !== 1 && 
                    // d.data.id !== 6 && 
                    // d.data.id !== 7 && 
                    // d.data.id !== 8 && 
                    // d.data.id !== 9) {
                    // console.log("I WILL WORK ON " + d.data.name + " " + d.data.id)

                    var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                        middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                        endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                    //Flip the direction of the arc by switching the start en end point (and sweep flag)
                    //of those elements that are below the horizontal line
                    var newStart = endLoc.exec(newArc)[1];
                    var newEnd = startLoc.exec(newArc)[1];
                    var middleSec = middleLoc.exec(newArc)[1];

                    //Build up the new arc notation, set the sweep-flag to 0
                    newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                }//if

                //Create a new invisible arc that the text can flow along
                svg.append("path")
                    .attr("class", "hiddenDonutArcs")
                    .attr("id", "donutArc" + i)
                    .attr("d", newArc)
                    .style("fill", "none");

                // let turnerX = Math.cos(d.startAngle + turnerAdd) * width / 2;
                // let turnerY = Math.sin(d.startAngle + turnerAdd) * height / 2;
                // beams with clickable circles
                let turnerAdd = (d.endAngle - d.startAngle) / 4;
                let adj = 4 * Math.PI / 9;
                let turnerX = Math.cos(d.startAngle + turnerAdd - adj) * width / 2;
                let turnerY = Math.sin(d.startAngle + turnerAdd - adj) * height / 2;

                let factor = 0.04;
                let sw = 3;

                // single beam
                svg.append('line')
                    // .style("stroke", "lightgreen")
                    .style("stroke", ubb.beobachtungsfelder[d.data.id - 1].color)
                    .style("stroke-width", sw)
                    .attr("class", "vanillaBeam")
                    .attr("id", "vanillaBeam" + i)
                    .attr("x1", turnerX * factor * 5)
                    .attr("y1", turnerY * factor * 5)
                    .attr("x2", turnerX * factor * 15)
                    .attr("y2", turnerY * factor * 15);

                let r = 6;
                let textOffsetX = 10;
                let textOffsetY = 3;

                let level = 0;
                let currentData = d;

                for (let i = 19; i > 0; i--) {
                    if (i === r ||
                        i === (r + 2) ||
                        i === (r + 4) ||
                        i === (r + 6) ||
                        i === (r + 8)) {
                        let circleX = turnerX * (i * factor);
                        let circleY = turnerY * (i * factor);


                        svg.append('text')
                            .attr('x', circleX) // x-Koordinate des Kreismittelpunkts
                            .attr('y', circleY) // y-Koordinate des Kreismittelpunkts
                            // .text(level) // Der darzustellende Text
                            .text(function (d) {
                                if (level === 0) {
                                    // return "N";
                                    return "0";
                                }
                                else {
                                    return level;
                                }
                            })
                            .attr('font-size', '0.9em')
                            .attr('fill', '#000000')
                            .style('text-anchor', 'middle') // Zentriert den Text horizontal
                            .style('dominant-baseline', 'middle'); // Zentriert den Text vertikal

                        svg.append('circle')
                            .style("cursor", "pointer")
                            .attr('cx', circleX)
                            .attr('cy', circleY)
                            .attr('r', function (d, i) {
                                if (component.props.parentState.b[currentData.data.id - 1].level === level) {
                                    return 14;
                                }
                                else {
                                    return 8;
                                }
                            })
                            .attr("id", "levelArc_" + d.data.id + "_" + level)

                            // .attr('fill', ubb.beobachtungsfelder[d.data.id - 1].color)
                            .attr('fill', function (d, i) {
                                if (component.props.parentState.b[currentData.data.id - 1].level === level) {
                                    return ubb.beobachtungsfelder[currentData.data.id - 1].color;
                                }
                                else {
                                    return "rgba(255,255,255,0.2)"
                                }
                            })
                            .attr('stroke', '#ffffff')
                            .attr('stroke-width', '0.1em')
                            .on("click", (event) => {
                                component.props.backCallerHandleChangeLevel(event);
                            })
                            .on("mouseover", function (d) {
                                d3.select(this)
                                    .transition()
                                    .delay(0)
                                    .duration(250)
                                    // .style("fill", "grey")
                                    .style("fill", function (d) {
                                        return ubb.beobachtungsfelder[currentData.data.id - 1].color;
                                    })
                                    .style("cursor", function (d) {
                                        const id = d3.select(this).attr("id").split("_");
                                        const rOId = Number(id[2]);
                                        const bIdLevel = component.props.parentState.b[currentData.data.id - 1].level;

                                        if (bIdLevel === rOId) {
                                            return "not-allowed"
                                        }
                                        else {
                                            return "pointer"
                                        }
                                    })
                            })
                            .on("mouseout", function (d) {
                                const id = d3.select(this).attr("id").split("_");
                                const rOId = Number(id[2]);
                                const bIdLevel = component.props.parentState.b[currentData.data.id - 1].level;

                                // console.log(id, bIdLevel);
                                d3.select(this)
                                    .transition()
                                    .delay(0)
                                    .duration(250)
                                    .style("fill", function (d) {
                                        if (bIdLevel === rOId) {
                                            return ubb.beobachtungsfelder[currentData.data.id - 1].color;
                                        }
                                        else {
                                            return "rgba(255,255,255,0.2)"
                                        }
                                    })
                            });
                        level++;
                    }
                }
            }).on("click", function (event, d) {
                // POPOVER PARTY!!!
                // component.handlePopovers(event, this);
                component.handlePopoversWithRef(event, this);
            })
            .on("mouseover", function (d) {
                // console.log(d);
                d3.select(this)
                    .transition()
                    .delay(0)
                    .duration(500)
                    .style('stroke', '#000000')
                // .style("fill", "#97c4df")
                // .style("fill", 'url(#gradient)')
            })
            .on("mouseout", function (d) {
                d3.select(this)
                    .transition()
                    .delay(0)
                    .duration(500)
                    .style('stroke', 'none')
                // .style("fill", function (d) {
                //     return ubb.beobachtungsfelder[d.data.id - 1].color;
                // })
                // .style("pointer", "cursor")
            })

        //Append the label names on the outside
        svg.selectAll(".donutText")
            .data(pie(donutData))
            .enter()
            .append("text")
            // .insert("text", ":first-child")

            .attr("class", "donutText")
            //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
            // .attr("dy", function (d, i) { return (d.endAngle > 90 * Math.PI / 180 ? 18 : -11); })
            .attr("dy", function (d, i) {
                // return ((d.data.id > 1 && d.data.id < 6) ? -21 : 21);
                return ((d.data.id > 1 && d.data.id < 6) ? -21 : 21);
            })
            .append("textPath")
            .style("cursor", "pointer")

            .attr("startOffset", "50%")
            .style("text-anchor", "middle")
            .style("fill", function (d, i) {
                return ((d.data.id === 1 || d.data.id === 2) ? "#000000" : "#ffffff");
            })
            .attr("xlink:href", function (d, i) { return "#donutArc" + i; })
            .attr('font-size', outerCircleFontSize)
            .each(function (d, i) {
                // console.log(i)
                var text = d3.select(this);
                var donutText = ubb.beobachtungsfelder[d.data.id - 1].donutText;
                let dy = "1.3em";
                switch (i) {
                    case 0:
                    case 5:
                    case 6:
                    case 8:
                        dy = "1.5em";
                        break;
                    case 7:
                        dy = "2.2em";
                        break;
                    case 1: // klassenklima
                        dy = "-1.5em";
                        break;
                    case 2:
                    case 3:
                    case 4:
                        dy = "-2.2em";
                        break;
                }
                if (Array.isArray(donutText) && donutText.length === 2) {
                    text.append("tspan")
                        .attr("x", 0)
                        .attr("dy", dy)
                        .text(donutText[0]);
                    text.append("tspan")
                        .attr("x", 0)
                        .attr("dy", "1.2em")
                        .text(donutText[1]);
                } else {
                    text.append("tspan")
                        .attr("x", 0)
                        .attr("dy", dy)
                        .text(donutText[0])
                        .attr("z-index", "-10000");
                }
            });
        // console.log("and he left drawdonut()")
        // const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        // const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        //     return new bootstrap.Popover(popoverTriggerEl);
        // });
    }

    constructor(props) {
        super(props);
        this.popoverElement = React.createRef();
        this.popoverInstance = null;
    }

    componentDidMount() {
        this.drawDonut();

        // this.handleClickOutside = (event) => {
        //     if (!event.target.closest('.popover') && !event.target.matches('.donutArcs')) {
        //         document.querySelectorAll('.donutArcs').forEach(popoverTrigger => {
        //             let popoverInstance = bootstrap.Popover.getInstance(popoverTrigger);
        //             if (popoverInstance) {
        //                 popoverInstance.hide();
        //             }
        //         });
        //     }
        // };
        // document.addEventListener('click', this.handleClickOutside);

        document.addEventListener('click', this.handleDocumentClick, true);
    }

    componentWillUnmount() {
        // document.querySelectorAll('.donutArcs').forEach(popoverTrigger => {
        //     let popoverInstance = bootstrap.Popover.getInstance(popoverTrigger);
        //     if (popoverInstance) {
        //         popoverInstance.hide();
        //     }
        // });
        // document.removeEventListener('click', this.handleClickOutside);

        this.destroyPopover();
        document.removeEventListener('click', this.handleDocumentClick, true);
    }

    handleDocumentClick = (event) => {
        if (!event.target.closest('.popover') && !event.target.matches('.donutArcs')) {
            this.destroyPopover();
        }
    };

    // hidePopover = () => {
    //     if (this.popoverInstance) {
    //         console.log("alive 1");
    //         this.popoverInstance.hide();
    //         console.log("alive 2");
    //         this.popoverInstance.dispose();
    //         console.log("alive 3");
    //         this.popoverInstance = null;
    //     }
    // };

    destroyPopover = () => {
        if (this.popoverInstance) {
            this.popoverInstance.dispose();
            this.popoverInstance = null;
        }
    };

    componentDidUpdate(prevProps, prevState) {
        setTimeout(() => {
            this.drawDonut();
        }, 100);
    }

    render() {
        return (
            <>
                <h2>Kreisdarstellung</h2>
                <div className="unselectable" id="ubbchart"></div>

                <p className="d-inline-flex gap-1 no-print">
                    <Button variant="outline-dark" className="p-1 my-3 btn-sm"
                        data-bs-toggle="collapse" href="#collapseStufen" role="button" aria-expanded="false" aria-controls="collapseStufen">
                        <span className="fw-bold">Erläuterungen zu den Stufen</span>
                    </Button>
                </p>

                <div className="collapse pb-4 no-print" id="collapseStufen">
                    <Container className="px-0"
                        style={{ width: (Math.min(window.innerWidth, window.innerHeight) / 1.5) }}
                    >
                        <Row key="some_key" className="mb-2">
                            <Col md={2} className="border p-2 mx-1 rounded fw-bold">
                                Stufe
                            </Col>
                            <Col className="border p-2 mx-2 text-start rounded fw-bold">
                                Erläuterung
                            </Col>
                        </Row>
                        {this.props.ubb.stufen.map((stufe, index) => (
                            <Row key={index} className="mb-2">
                                <Col md={2} className="border p-2 mx-1 rounded">
                                    {index}
                                </Col>
                                <Col className="border p-2 mx-2 text-start rounded">
                                    {stufe.text}
                                </Col>
                            </Row>
                        ))}
                    </Container>
                </div>
            </>
        )
    }
}
