import './PageNotFound.css';

const PageNotFound = ({ parentState, backCallerHandleCancelModalAreYouSure }) => {

    return (
        <>
            <div className="container-fluid text-start">
                <h2 className="">Die von Ihnen gew&uuml;nschte Seite konnte nicht gefunden werden...</h2>
                <div className="row">
                    <div className="col-12 text-start">
                        <p>Bitte pr&uuml;fen Sie Ihre Eingabe. Falls der Fehler weiterhin besteht, melden Sie den Fehler bitte.
                            </p>
                            <p>Wenn m&ouml;glich mit einem Bildschirmfoto dieser Seite - am besten mit gut lesbarer
                            URL (die Browserzeile oben quer, wo die von Ihnen aufgerufene Seite steht). Sie können auch gerne Datum und Uhrzeit angeben und
                            die von Ihnen gewünschte Seite (hier: <span className="fw-bold">{window.location.href}</span>).
                        </p>
                        <h4>Vielen Dank für Ihre Mithilfe!</h4>
                            

                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;