import { PureComponent } from 'react'

export class Notes extends PureComponent {

    extractRGBValues = (hex) => {
        const regex = /rgb\((\d+),(\d+),(\d+)\)/;
        const matches = hex.match(regex);

        if (matches) {
            const r = parseInt(matches[1], 10);
            const g = parseInt(matches[2], 10);
            const b = parseInt(matches[3], 10);
            return { r, g, b };

        }

        return { r: 0, g: 0, b: 0 };
    }

    hex2rgb = (hex) => {
        console.log("hex: " + hex);
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        // return {r, g, b} 
        return { r, g, b };
    }

    handleCopyData = (e) => {
        var ta = document.getElementById("f_protocol_ta1");
        var start = ta.selectionStart;
        var finish = ta.selectionEnd;
        var sel = ta.value.substring(start, finish);
        // if (sel.length > 0) {
        this.props.backCallerCopyData(e, sel);
        // }
    }

    handleChangeProtocolData = (e) => {
        this.props.backCallerChangeProtocolData(e);
    }

    handleChangeMetaData = (e) => {
        this.props.backCallerChangeMetaData(e);
    }

    handleContextMenu = (e) => {
        e.preventDefault();
        this.props.backCallerContextMenu(e);
    }

    handleCloseContextMenu = (e) => {
        this.props.backCallerCloseContextMenu(e);
    }

    handleTouchStart = (event) => {
        this.touchTimeout = setTimeout(() => {
            this.handleContextMenu(event);
        }, 500);
    };

    handleTouchEnd = () => {
        clearTimeout(this.touchTimeout);
    };

    handleTouchMove = () => {
        clearTimeout(this.touchTimeout);
    };

    componentDidMount() {
        document.addEventListener('click', this.handleCloseContextMenu);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleCloseContextMenu);
    }

    render() {
        let ubb = this.props.ubb;
        return (
            <>
                <h2>Notizen</h2>

                <div className="container-fluid">
                    <form>
                        <div onContextMenu={this.handleContextMenu}
                            onTouchStart={this.handleTouchStart}
                            onTouchEnd={this.handleTouchEnd}
                            onTouchMove={this.handleTouchMove}
                        >
                            {this.props.parentState.helper.ctxMenu.visible && (
                                <div
                                    className="dropdown-menu show no-print"
                                    style={{
                                        position: "absolute",
                                        top: this.props.parentState.helper.ctxMenu.y, left: this.props.parentState.helper.ctxMenu.x,
                                    }}
                                >
                                    <div style={{ padding: "0px 0px 0px 10px" }}
                                        className="dropdown-item fw-bold">Markierten Text einfügen in...</div>
                                    <hr className="dropdown-divider" />

                                    {ubb.dimensionen.map(({ name, id }, index) => {
                                        return (
                                            <>
                                                <div className="dropdown-item fw-bold text.ce fs-6"
                                                    style={{
                                                        padding: "0px 0px 0px 10px",
                                                        fontSize: "0.8rem"
                                                    }}>{name}</div>
                                                <hr className="dropdown-divider"
                                                    style={{ padding: "0px", margin: "0px" }} />

                                                {ubb.beobachtungsfelder.map((
                                                    { name, d, merkmale, merkmale_sub, color, sub, ...rest }, index) => {
                                                    let fgCol = index < 2 ? "black" : "white";
                                                    // let rgb = this.hex2rgb(ubb.beobachtungsfelder[index].color);
                                                    let rgb = this.extractRGBValues(ubb.beobachtungsfelder[index].color);
                                                    return (
                                                        <>
                                                            {((id - 1) === d) &&

                                                                <>
                                                                    <div style={{ padding: "0px", margin: "0px" }}>
                                                                        <a href="#"
                                                                            style={{
                                                                                backgroundColor: "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ", 0.9)",
                                                                                color: fgCol,
                                                                                // fontSize: "0.8rem"
                                                                            }}
                                                                            className="dropdown-item fw-bold"
                                                                            onClick={(e) => this.handleCopyData(index)}>
                                                                            {/* {ubb.dimensionen[d].name} &gt;&gt; {name}</a> */}
                                                                            &gt;&gt; {name}</a>
                                                                        {(index < 8) && <hr className="dropdown-divider"
                                                                            style={{ padding: "0px", margin: "0px" }} />}
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </>
                                        )
                                    })}
                                </div>
                            )}

                            <div className="row mb-3">
                                {/* <label htmlFor="f_protocol_ta1" className="col-sm-2 col-form-label no-print">Eintragungen</label> */}
                                <div className="col">
                                    <textarea className="form-control" id="f_protocol_ta1" rows="23" name="f_protocol_ta1"
                                        value={this.props.parentState.protocol.data}
                                        onChange={this.handleChangeProtocolData}
                                        placeholder="Ihre Notizen..." />
                                </div>
                            </div>

                        </div>

                    </form>
                </div>

            </>
        )
    }
}