import { PureComponent } from 'react'

import './Meta.css';

export class Meta extends PureComponent {

    handleChangeMetaData = (e) => {
        this.props.backCallerChangeMetaData(e);
    }

    render() {
        return (
            <>
                <h2>Metadaten</h2>

                <div className="container-fluid ps-4">
                    <form>
                        <div className="row mb-3">
                            <label htmlFor="f_md_1" className="text-start col-sm-3 col-form-label">Unterrichtende Lehrkraft</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" id="f_md_1" name="f_md_1"
                                    value={this.props.parentState.md[0].value}
                                    onChange={this.handleChangeMetaData}
                                    placeholder="Unterrichtende Lehrkraft" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="f_md_2" className="text-start col-sm-3 col-form-label">Schule</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" id="f_md_2" name="f_md_3"
                                    value={this.props.parentState.md[1].value}
                                    onChange={this.handleChangeMetaData}
                                    placeholder="Schule" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="f_md_3" className="text-start col-sm-3 col-form-label">Klasse</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" id="f_md_3" name="f_md_3"
                                    value={this.props.parentState.md[2].value}
                                    onChange={this.handleChangeMetaData}
                                    placeholder="Klasse" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="f_md_dstart" className="text-start col-sm-3 col-form-label">Datum</label>
                            <div className="col-sm-4">
                                <input id="f_md_dstart" className="form-control pointerFinger" type="date"
                                    max="2050-12-31"
                                    onChange={this.handleChangeMetaData}
                                    value={this.props.parentState.md[8].value}
                                />
                                <span id="startDateSelected"></span>
                            </div>
                        </div>


                        <div className="row mb-3">
                            <label htmlFor="f_md_tstart" className="text-start col-sm-3 col-form-label">Zeit von</label>
                            <div className="col-sm-4">
                                <input type="time" 
                                    className="form-control pointerFinger" id="f_md_tstart" name="f_md_tstart"
                                    value={this.props.parentState.md[9].value}
                                    onChange={this.handleChangeMetaData}
                                    placeholder="Zeit von" />
                            </div>
                            <label htmlFor="f_md_tend" className="text-start col-sm-1 col-form-label">bis</label>
                            <div className="col-sm-4">
                                <input type="time" className="form-control pointerFinger" id="f_md_tend" name="f_md_tend"
                                    value={this.props.parentState.md[10].value}
                                    onChange={this.handleChangeMetaData}
                                    placeholder="bis" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="f_md_6" className="text-start col-sm-3 col-form-label">Thema</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" id="f_md_6" name="f_md_6"
                                    value={this.props.parentState.md[5].value}
                                    onChange={this.handleChangeMetaData}
                                    placeholder="Thema" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="f_md_reason" className="text-start col-sm-3 col-form-label">Anlass</label>
                            <div className="col-sm-4">
                                <select id="f_md_reason" className="form-select pointerFinger"
                                    aria-label="Default select example"
                                    onChange={this.handleChangeMetaData}
                                    value={this.props.parentState.md[7].value}>
                                    <option value="0">Bitte treffen Sie eine Wahl</option>
                                    <option value="1">Hospitation</option>
                                    <option value="2">Beratung</option>
                                    <option value="3">Beurteilung</option>
                                    <option value="4">Sonstiges</option>
                                </select>
                            </div>


                            {/* <label htmlFor="f_md_dend" className="col-sm-2 col-form-label">Ende</label>
                            <div className="col-sm-4">
                                <input id="f_md_dend" className="form-control" type="date" />
                                <span id="endDateSelected"></span>
                            </div> */}
                        </div>
                        {/* <div className="row mb-3">
                            <label htmlFor="f_md_reason" className="col-sm-2 col-form-label">Begründung</label>
                            <div className="col-sm-10">
                                <select id="f_md_reason" className="form-select" aria-label="Default select example">
                                    <option value="0" selected>Bitte treffen Sie eine Wahl</option>
                                    <option value="1">Vorbereitung</option>
                                    <option value="2">Hospitation</option>
                                    <option value="3">beurteilender Unterrichtsbesuch</option>
                                    <option value="4">Lehrprobe</option>
                                    <option value="5">annlassbezogene Beurteilung</option>
                                    <option value="6">Sonstige</option>
                                </select>
                            </div>
                        </div> */}




                        {/* <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <label htmlFor="exampleDataList" className="form-label">Datalist example</label>
                                <input className="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search..." />
                                <datalist id="datalistOptions">
                                    <option value="San Francisco" />
                                    <option value="New York" />
                                    <option value="Seattle" />
                                    <option value="Los Angeles" />
                                    <option value="Chicago" />
                                </datalist>

                            </div>
                        </div> */}



                        {/* <div id={"divTextarea_" + index} className={"row" + ((this.props.parentState.pWith === 2) && " collapse")}>
                            <textarea className="form-control h-100 d-inline-block mb-3 ubbTa"
                                id={"textarea_" + index} autoComplete="off"
                                name={"textarea_" + index}
                                value={this.props.parentState.b[index].text}
                                onChange={this.handleChangeTextData}
                                placeholder="Anmerkungen"
                                rows="10" />
                        </div> */}

                        {/* <div className="row mb-3">
                            <label htmlFor="f_md_ta1" className="col-sm-2 col-form-label">Anmerkungen</label>
                            <div className="col-sm-10">
                                <textarea className="form-control" id="f_md_ta1" rows="3" name="f_md_ta1"
                                    value={this.props.parentState.md[5].value}
                                    onChange={this.handleChangeMetaData}
                                    placeholder="Anmerkungen" />
                            </div>
                        </div> */}



                    </form>
                </div>
            </>
        )
    }
}