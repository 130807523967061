import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Container, Row, Col } from 'react-bootstrap';
import { Donut } from './pages/Donut/Donut';
import HiddenCanvases from './pages/Canvas/HiddenCanvases';
import { ubb } from "./pages/Consts.js"

const convertSVGToPNG = (svgElement) => {
    return new Promise((resolve, reject) => {
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            URL.revokeObjectURL(url);
            const pngData = canvas.toDataURL('image/png');
            resolve(pngData);
        };
        img.onerror = (error) => {
            URL.revokeObjectURL(url);
            reject(error);
        };
        img.src = url;
    });
};

const PrintHeaderTable = ({ parentState }) => (
    <div className="print-header">
        <Container fluid>
            <Row className="mb-1">
                <Col sm={8} className="border rounded">Unterrichtende Lehrkraft: <span className="fw-bold">{parentState.md[0].value}</span></Col>
                <Col className="border rounded">Datum: <span className="fw-bold">
                    {parentState.md[8].value.length > 0 ? new Date(parentState.md[8].value).toLocaleDateString('de-DE') : ""}
                </span></Col>
            </Row>
            <Row className="mb-1">
                <Col sm={8} className="border rounded">Schule: <span className="fw-bold">{parentState.md[1].value}</span></Col>
                <Col className="border rounded">Zeit von <span className="fw-bold">
                    {parentState.md[9].value.length > 0 ? parentState.md[9].value : "__:__"}
                </span>
                    &nbsp;bis <span className="fw-bold">
                        {parentState.md[10].value.length > 0 ? parentState.md[10].value : "__:__"}

                    </span></Col>
            </Row>
            <Row>
                <Col sm={3} className="border rounded">Klasse: <span className="fw-bold">{parentState.md[2].value}</span></Col>
                <Col className="border rounded">Thema: <span className="fw-bold">{parentState.md[5].value}</span></Col>
            </Row>
        </Container>
    </div>
);

const noop = () => { };

const createNotesPrintView = (pData) => {
    return pData.split('\n').map((line, index) => (
        <div key={index}><p>{line}</p></div>
    ));
};

const createBTextPrintView = (pData) => {
    return pData.split('\n').map((line, index) => (
        <div key={index}>{line}</div>
    ));
};

// export const handlePrintRequestAll = async ({ parentState, loc }) => {
//     let images = [];

//     const handleImagesGenerated = (generatedImages) => {
//         images = generatedImages;
//         console.log(images);
//     };

//     const printWindow = window.open('', '_blank');
//     const rootElement = document.createElement('div');
//     printWindow.document.body.appendChild(rootElement);

//     printWindow.document.title = 'Druck Vorschau [UBB-Basismodell]';

//     Array.from(document.styleSheets).forEach(styleSheet => {
//         if (styleSheet.href) {
//             const newLinkElement = printWindow.document.createElement('link');
//             newLinkElement.rel = 'stylesheet';
//             newLinkElement.href = styleSheet.href;
//             printWindow.document.head.appendChild(newLinkElement);
//         } else if (styleSheet.cssRules) {
//             const newStyleElement = printWindow.document.createElement('style');
//             Array.from(styleSheet.cssRules).forEach(rule => {
//                 newStyleElement.appendChild(printWindow.document.createTextNode(rule.cssText));
//             });
//             printWindow.document.head.appendChild(newStyleElement);
//         }
//     });

//     const style = document.createElement('style');
//     style.innerHTML = `
//           body {
//             font-family: Arial, sans-serif;
//             margin: 40px;
//           }

//           @media print {
//             @page {
//               size: lansdscape;
//               margin-top: 40px;
//             }

//             body, html {
//                 margin: 0;
//                 padding: 0;
//             }

//             .print-header {
//                 display: block;
//             }

//             .print-content {
//                 margin-top: 20px;
//             }

//             .page-break {
//                 page-break-before: always;
//             }

//             .print-content > * {
//                 page-break-inside: avoid;
//             }

//            .no-page-break {
//               page-break-inside: avoid;
//             }
//           }
//         `;

//     printWindow.document.head.appendChild(style);
//     const root = ReactDOM.createRoot(rootElement);

//     let ubbChartDivAvailable = true;
//     const ubbChartDiv = document.getElementById('ubbchart');

//     if (ubbChartDiv === null) {
//         ubbChartDivAvailable = false;

//         const container = document.createElement('div');
//         container.style.position = 'absolute';
//         container.style.top = '-9999px';
//         container.style.left = '-9999px';
//         container.id = 'ubbchart';
//         document.body.appendChild(container);

//         const rootD3 = ReactDOM.createRoot(container);
//         rootD3.render(
//             <Donut
//                 parentState={parentState}
//                 ubb={ubb}
//                 backCallerHandleChangeLevel={noop}
//             />
//         );
//         await new Promise(resolve => setTimeout(resolve, 200)); // VERY ugly hack!
//     }

//     const svgElement = document.getElementById('ubbchart').querySelector('svg');
//     const pngData = await convertSVGToPNG(svgElement);



//     root.render(
//         <>
//             <HiddenCanvases parentState={parentState} onImagesGenerated={handleImagesGenerated} />

//             <PrintHeaderTable parentState={parentState} />

//             <div className="mt-4">
//                 <h2>Kreisdarstellung</h2>
//                 <img src={pngData} alt="Kreisdarstellung"
//                     style={{ maxHeight: '80%', width: 'auto' }}
//                 />
//             </div>

//             <div className="page-break" />

//             <div className="print-content">
//                 <h2>Notizen</h2>
//                 {createNotesPrintView(parentState.protocol.data)}
//             </div>

//             <div className="page-break" />
//             <div className="container-fluid">
//                 <h2>Tabellarische Darstellung</h2>
//                 {ubb.dimensionen.map(({ name, id }, index) => (
//                     <div key={index} className="row row-cols-1 print-content">
//                         <div className="col mb-3">
//                             <h3>Dimension {name}</h3>

//                             <div className="row">
//                                 <div className="p-2 col-4 border text-start fw-bold">Beobachtungsfeld</div>
//                                 <div className="p-2 col-7 border text-start fw-bold">Beschreibung</div>
//                                 <div className="p-2 col-1 border fw-bold text-center">Stufe</div>
//                             </div>

//                             {ubb.beobachtungsfelder
//                                 .filter(feld => feld.d === id - 1)
//                                 .map((feld, feldIndex) => (
//                                     parentState.b[feld.id - 1] && (
//                                         <React.Fragment key={feld.id}>
//                                             <div key={feldIndex} className="row">
//                                                 <div className="col-4 border text-start fw-bold align-middle d-flex align-items-center"
//                                                     style={{ color: `var(--ubb-color-${(feld.id - 1)})` }}
//                                                 >{feld.name}</div>
//                                                 <div className="col-7 border text-start align-middle d-flex align-items-center">{feld.sub}</div>
//                                                 <div className="col-1 border d-flex justify-content-center align-items-center fw-bold"
//                                                     style={{ color: `var(--ubb-color-${feld.id - 1})` }}
//                                                 >
//                                                     {parentState.b[feld.id - 1].level}</div>
//                                             </div>

//                                             {/* <div key={feldIndex + '23'} className="row border-top-0">
//                                                 <div className="col border">
//                                                     {createBTextPrintView(parentState.b[feld.id - 1].text.length > 0 ? parentState.b[feld.id - 1].text : "Keine Eintragung.")}
//                                                 </div>
//                                             </div> */}

//                                             <div key={feldIndex + '42'} className="row mb-3 border-top-0">
//                                                 <div className="col border">
//                                                     {createBTextPrintView(parentState.b[feld.id - 1].text.length > 0 ? parentState.b[feld.id - 1].text : "Keine Eintragung.")}
//                                                 </div>
//                                             </div>
//                                         </React.Fragment>
//                                     )
//                                 ))}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             {images.map((image, index) =>
//                 image ? <img key={index} src={image} alt={`Canvas ${index}`} /> : <p key={index}>No image</p>
//             )}
//         </>
//     );

//     setTimeout(() => {
//         printWindow.document.close();
//         printWindow.focus();
//         printWindow.print();
//         root.unmount();
//         printWindow.close();
//     }, 500);

//     if (!ubbChartDivAvailable) {
//         const container = document.getElementById('ubbchart');
//         document.body.removeChild(container);
//     }
// }

let printRoot = null;

export const handlePrintRequestAll = async ({ parentState, loc }) => {
    let images = [];

    const handleImagesGenerated = (generatedImages) => {
        images = generatedImages;
        renderPrintContent();
    };

    // const doTheJob = () => {
    //     printRoot.unmount();
    //     printRoot = null;
    //     printWindow.close();
    // }

    const printWindow = window.open('', '_blank');
    const rootElement = document.createElement('div');
    printWindow.document.body.appendChild(rootElement);

    printWindow.document.title = 'Druck Vorschau [UBB-Basismodell]';

    Array.from(document.styleSheets).forEach(styleSheet => {
        if (styleSheet.href) {
            const newLinkElement = printWindow.document.createElement('link');
            newLinkElement.rel = 'stylesheet';
            newLinkElement.href = styleSheet.href;
            printWindow.document.head.appendChild(newLinkElement);
        } else if (styleSheet.cssRules) {
            const newStyleElement = printWindow.document.createElement('style');
            Array.from(styleSheet.cssRules).forEach(rule => {
                newStyleElement.appendChild(printWindow.document.createTextNode(rule.cssText));
            });
            printWindow.document.head.appendChild(newStyleElement);
        }
    });

    const style = document.createElement('style');
    style.innerHTML = `
        body {
            font-family: Arial, sans-serif;
            margin: 40px;
            
        }
        
        @media print {
            @page {
                /* size: portrait; */
                margin-top: 40px;
                }
                
                body, html {
                    /* margin: 0; */
                    padding: 0;
                    width: 210mm;
                    margin: 0 auto;
                }
                    
                .half-image img {
                    width: 100%;
                    height: auto;
                    clip-path: inset(0 25% 0 0);
                }

            .print-header {
                display: block;
            }
            .print-content {
                margin-top: 20px;
            }
            .page-break {
                page-break-before: always;
            }
            .print-content > * {
                page-break-inside: avoid;
            }
            .no-page-break {
                page-break-inside: avoid;
            }
        }
    `;
    printWindow.document.head.appendChild(style);

    const renderPrintContent = () => {
        printRoot.render(
            <>

                {/* <div><button onClick={(e) => doTheJob}>klicke here</button></div> */}

                {}
                <PrintHeaderTable parentState={parentState} />

                <div className="mt-4">
                    <h2>Kreisdarstellung</h2>
                    <img src={pngData} alt="Kreisdarstellung" style={{ maxHeight: '80%', width: 'auto' }} />
                </div>

                {parentState.protocol.data && parentState.protocol.data.trim() && (
                    <>
                        <div className="page-break" />
                        <div className="print-content">
                            <h2>Notizen</h2>
                            {createNotesPrintView(parentState.protocol.data)}
                        </div>
                    </>
                )}

                <div className="page-break" />
                <div className="container-fluid">
                    <h2>Tabellarische Darstellung</h2>
                    {ubb.dimensionen.map(({ name, id }, index) => (
                        <div key={index} className="row row-cols-1 print-content">
                            <div className="col mb-3">
                                <h3>Dimension {name}</h3>

                                <div className="row">
                                    <div className="p-2 col-4 border text-start fw-bold">Beobachtungsfeld</div>
                                    <div className="p-2 col-7 border text-start fw-bold">Beschreibung</div>
                                    <div className="p-2 col-1 border fw-bold text-center">Stufe</div>
                                </div>

                                {ubb.beobachtungsfelder
                                    .filter(feld => feld.d === id - 1)
                                    .map((feld, feldIndex) => (
                                        parentState.b[feld.id - 1] && (
                                            <React.Fragment key={feld.id}>
                                                <div key={feldIndex} className="row">
                                                    <div className="col-4 border text-start fw-bold align-middle d-flex align-items-center"
                                                        style={{ color: `var(--ubb-color-${(feld.id - 1)})` }}
                                                    >{feld.name}</div>
                                                    <div className="col-7 border text-start align-middle d-flex align-items-center">{feld.sub}</div>
                                                    <div className="col-1 border d-flex justify-content-center align-items-center fw-bold"
                                                        style={{ color: `var(--ubb-color-${feld.id - 1})` }}
                                                    >
                                                        {parentState.b[feld.id - 1].level}</div>
                                                </div>

                                                <div key={feldIndex + '23'} className="row mb-3 border-top-0">
                                                    <div className="col border">
                                                        {createBTextPrintView(parentState.b[feld.id - 1].text.length > 0 ? parentState.b[feld.id - 1].text : "Keine Eintragung.")}
                                                    </div>
                                                </div>

                                                {images[feld.id - 1] && (
                                                    <div key={feldIndex + '42'} className="row mb-3 border-top-0">
                                                        <div className="col border half-image">
                                                            <img
                                                                src={images[feld.id - 1]} alt={`Stiftaufzeichnung: ${feld.name}`} />
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )
                                    ))}
                            </div>
                        </div>
                    ))}
                </div>
                {/* {images.length > 0 && images
                    .filter(image => image)
                    .map((image, index) => (
                        <img key={index} src={image} alt={`Canvas ${index}`} />
                    ))
                } */}
            </>
        );

        setTimeout(() => {
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printRoot.unmount();
            printRoot = null;
            printWindow.close();
        }, 750);
    };

    if (!printRoot) {
        printRoot = ReactDOM.createRoot(rootElement);
    }

    let ubbChartDivAvailable = true;
    const ubbChartDiv = document.getElementById('ubbchart');

    if (!ubbChartDiv) {
        ubbChartDivAvailable = false;

        const container = document.createElement('div');
        container.style.position = 'absolute';
        container.style.top = '-9999px';
        container.style.left = '-9999px';
        container.id = 'ubbchart';
        document.body.appendChild(container);

        const rootD3 = ReactDOM.createRoot(container);
        rootD3.render(
            <Donut
                parentState={parentState}
                ubb={ubb}
                backCallerHandleChangeLevel={noop}
            />
        );
        await new Promise(resolve => setTimeout(resolve, 200));
    }

    const svgElement = document.getElementById('ubbchart').querySelector('svg');
    const pngData = await convertSVGToPNG(svgElement);

    printRoot.render(
        <HiddenCanvases parentState={parentState} onImagesGenerated={handleImagesGenerated} />
    );

    if (!ubbChartDivAvailable) {
        const container = document.getElementById('ubbchart');
        document.body.removeChild(container);
    }
};


